import React, { Fragment } from "react";
import Preview3D from "./Preview3D";
import FlipVideo from "./FlipVideo";
import FlipButton from "./FlipButton";
import { TouchScroll, Image, Icon, Button } from "./../../UI";
import AddToKeteButton from "./AddToKeteButton";
import { mapMedia } from "./../../Utils/Responsive";
import classnames from "classnames";
import { connect } from "react-redux";
import { setPage } from './../';
import "./Shop.css"

const mapStateToProps = state => ({
	media: state.ui.media,
});

class Shop extends React.Component {

	state = {
		selectedPreview: 0,
	}

	renderFlipButton = (key) => <FlipButton key={key} style={{
		position: "absolute",
		left: "50%",
		top: "0px",
		transform: "translateX(-50%)",
	}} />

	renderPreview = (previews) => {
		let { selectedPreview } = this.state;
		let preview = previews[selectedPreview];
		let { type, src, srcFront, srcBack, aspect, widths, alt } = preview;
		let inner = null;
		switch(type){
			case "3d":
				inner = <Preview3D preview={preview} backgroundColor="yellow" />
			break;
			case "dual":
				inner = <Fragment>
					<div style={{
						position: "absolute",
						width: "100%",
						top: "50px",
						bottom: "0px",
					}} className={classnames("dual-image", "front")}>
						<Image
							key={src}
							type={type}
							src={srcFront}
							aspect={aspect}
							widths={widths}
							expand
							alt="Preview Thumbnail"
						/>
					</div>
					<div className="dual-image-back-container" style={{
						position: "absolute",
						width: "100%",
						top: "50px",
						bottom: "0px",
					}}>
						<div style={{
							position: "absolute",
							width: "100%",
							height: "100%",
						}} className={classnames("dual-image", "back")}>
							<Image
								key={src}
								type={type}
								src={srcBack}
								aspect={aspect}
								widths={widths}
								extension={type === "video" ? "mp4" : "jpg"}
								expand
								alt="Preview Thumbnail"
							/>
						</div>
					</div>
					{ this.renderFlipButton(srcFront) }
				</Fragment>
			break;
			case "video":
				inner = <Fragment>
					<div style={{
						position: "absolute",
						width: "100%",
						top: "50px",
						bottom: "0px",
					}}>
						<FlipVideo
							key={src}
							type="video"
							src={src}
							aspect={aspect}
							widths={widths}
							extension={type === "video" ? "mp4" : "jpg"}
							expand
							fit
							alt={alt}
						/>
					</div>
					{ this.renderFlipButton() }
				</Fragment>
			break;
			case "image":
				inner = <div style={{
					position: "absolute",
					width: "100%",
					top: "50px",
					bottom: "0px",
				}}>
					<Image
						key={src}
						src={src}
						aspect={aspect}
						widths={widths}
						extension={type === "video" ? "mp4" : "jpg"}
						expand
						alt={alt}
					/>
				</div>
			break;
			default:
				throw new Error(`Unhandled renderPreview type ${type}`);
		}
		return <div key={src} className="preview-container" style={{
			position: "relative",
			width: "100%",
			height: "100%",
			overflow: "hidden",
		}}>
			{ inner }
		</div>
	}

	renderPreviewThumbnail = (preview, i) => {
		let { type, src, srcFront, aspect, widths, videoThumbSrc } = preview;
		let selected = i === this.state.selectedPreview;
		let inner = null;
		switch(type){
			case "3d":
				inner = <Icon style={{width: "100%", height: "100%"}} icon="3d-preview" />;
			break;
			case "image":
				inner = <Image src={src} aspect={aspect} widths={widths} expand alt="Preview Thumbnail" />;
			break;
			case "dual":
				inner = <Image src={srcFront} aspect={aspect} widths={widths} expand alt="Preview Thumbnail" />;
			break;
			case "video":
				inner = <Image src={videoThumbSrc} aspect={aspect} widths={widths} expand alt="Preview Thumbnail" />;
			break;
			default: throw new Error(`Unhandled renderPreviewThumbnail type ${type}`);
		}
		return <div
			key={i}
			style={{
				display: "inline-block",
				marginRight: "6px",
				width: "80px",
				height: "80px",
				position: "relative",
				padding: "6px",
				cursor: "pointer"
			}}
			onClick={() => this.setState({selectedPreview: i})}
		>
			{
				inner
			}
			{
				selected ? <div style={{
					position: "absolute",
					top: "0px",
					left: "0px",
					right: "0px",
					bottom: "0px",
					border: `2px solid #001FFF`,
					pointerEvents: "none",
				}} /> : null
			}
		</div>
	}

	renderTitles = () => {
		return <div>
			<h2 style={{marginBottom: "0px"}}>Frankenstein;</h2>
			<h3 style={{marginTop: "6px"}}>or, The Modern Prometheus</h3>
			<p style={{fontSize: "14px"}}>Mary Shelley, 1818</p>
			<div style={{
				marginBottom: "18px",
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
			}}>
				<AddToKeteButton style={{
					display: "inline-flex"
				}} product="frankenstein" variant="verso" quantity={1} />
				<div style={{display: "inline-flex", flex: 1}} />
				<Button
					style={{display: "inline-flex"}}
					label="Resources"
					iconSide="right"
					theme="hollow"
					icon="lightbulb"
					onClick={() => setPage("resources")}
				/>
			</div>
		</div>
	}

	renderDescription = () => {
		return <Fragment>
			<p>This is a book. It is also an art object and an experiment in printing methods (modes).</p>
			<p>The book contains a story. One of the most recognisable stories in the English canon thanks to numerous film and television adaptations.</p>
			<p>The art accompanying the book also tells a story. An attempt to revise our expectations of such a well known story. More colour; less horror.</p>
			<p>Finally, the printing method deserves a story. A simple story in which our creator desired a more comfortable reading experience whilst lazing on the couch or lying in bed. Thus sprung Fliperature and this is where you enter the story. Your purchase of this book, art object, experiment supports a budding boutique publisher, a local printing press and artist.</p>
		</Fragment>
	}

	renderThumbnails = previews => {
		return <TouchScroll style={{
			// overflowY: "scroll",
			// overflow: "auto",
			whiteSpace: "nowrap",
			marginRight: "6px",
			paddingBottom: "6px",
		}}>
			{
				previews.map((t, i) => this.renderPreviewThumbnail(t, i))
			}
		</TouchScroll>
	}

	renderAttribution = () => {
		return <p><i>Cover art by <a rel="noopener noreferrer" href={"https://www.ginakiel.com"} target="_blank">Gina Kiel</a>.</i></p>
	}

	render(){
		let { media } = this.props;
		let previews = [
			// { type: "3d" },
			{
				type: "dual",
				srcFront: "assets/images/white-front",
				srcBack: "assets/images/white-back",
				aspect: 3457 / 4852,
				widths: [1920, 1280, 720, 360],
				alt: "Photo of Frankenstein",
			},
			{
				type: "image",
				src: "assets/images/white-spread",
				aspect: 5185 / 3456,
				widths: [1920, 1280, 720, 360],
				alt: "Frankenstein Page Spread"
			},
			{
				type: "video",
				src: "assets/videos/frankenstein-flip-preview",
				videoThumbSrc: "assets/images/video-thumbnail",
				aspect: 1280 / 720,
				widths: [640],
				alt: "Video of Frankenstein being flipped"
			},
			{
				type: "dual",
				srcFront: "assets/images/room-front",
				srcBack: "assets/images/room-back",
				aspect: 3216 / 4852,
				widths: [1920, 1280, 720, 360],
				alt: "Photo of Frankenstein in situ",
			},
		];
		return mapMedia(media, {
			tablet: <TouchScroll className="page-overflow-container" style={{
					display: "flex",
					flexDirection: "column"
				}}
			>
				<div className="page-overflow-content">
					{ this.renderTitles() }
					<div style={{
						position: "relative",
						width: "100%",
						height: "500px",
						margin: "6px 0px",
						// overflow: "hidden",
					}}>
						{ this.renderPreview(previews) }
					</div>
					<div>
						{ this.renderThumbnails(previews) }
					</div>
					{ this.renderDescription() }
					{ this.renderAttribution() }
				</div>
			</TouchScroll>,
			large: <TouchScroll
				className="page-overflow-container"
				style={{
					display: "flex",
					height: "100%",
					flexDirection: "row",
					width: "100%",
					justifyContent: "center",
				}}
			>
				<div className="preview-section" style={{
							flex: 1,
							maxWidth: "500px",
							height: "100%",
							display: "inline-flex",
							position: "relative",
							overflow: "hidden",
						}}>
				{ this.renderPreview(previews) }
				</div>
				<div className="info-section" style={{
					width: "350px",
					maxWidth: "50%",
					marginLeft: "24px",
					marginRight: mapMedia(media, {
						tablet: "0px",
						large: "0px",
						extraLarge: "48px",
					}),
					height: "100%",
					display: "inline-flex",
					flexDirection: "column",
					overflow: "hidden",
				}}>
					{
						this.renderTitles()
					}
					<div style={{
						flex: 1,
						paddingRight: "6px",
						overflowX: "hidden",
					}}>
						{
							this.renderDescription()
						}
					</div>
					{
						this.renderThumbnails(previews)
					}
					{
						this.renderAttribution()
					}
				</div>
			</TouchScroll>
		})
	}

}

export default connect(mapStateToProps)(Shop);