
export default (func, fps=60, options={}) => {
	options = Object.assign({
		iterator: "requestAnimationFrame",
	}, options);
	let stop = false;
	let frameCount = 0;
	let fpsInterval = fps !== -1 ? (1000 / fps) : 1;
	let now, elapsed;
	let then = Date.now();

	let returnMultiplier = options.returnAsFps ? (1 / (1000 / options.returnAsFps)) : 0.001;

	switch(options.iterator){
		case "requestAnimationFrame":
			let loop;
			loop = () => {
				if(!stop){
					requestAnimationFrame(loop);	
				}
				now = Date.now();
				elapsed = now - then;
				if(elapsed > fpsInterval){
					func(elapsed * returnMultiplier, {time: now, pTime: then, frameCount});
					then = now - (elapsed % fpsInterval);
					frameCount++;
				}
			}
			loop();
			return () => stop = true;
		case "setInterval":
			let interval = setInterval(() => {
				now = Date.now();
				elapsed = now - then;
				func(elapsed * returnMultiplier, {time: now, pTime: then, frameCount});
				then = now - (elapsed % fpsInterval);
				frameCount++;
			}, fpsInterval);
			return () => clearInterval(interval);
		default: throw new Error(`Unhandled loop iterator: `, options.iterator);
	}

}
