import Client from 'shopify-buy';
import { setKete } from "./../Redux/Kete";
import { incrementCheckoutComplete } from "./../Redux/UI";
import moment from "moment";

const client = Client.buildClient({
	domain: 'fliperature.myshopify.com',
	storefrontAccessToken: '44882f12ae9e4aa50613e003ac4fb6d0'
});

let checkout = null;
let shopError = null;

const wait = delay => new Promise(resolve => setTimeout(resolve, delay));

const products = {
	frankenstein: {
		shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMDI4OTU1OTE1OTQ=",
		title: "Frankenstein",
		subtitle: "Mary Shelley",
		id: "frankenstein",
		variants: {
			verso: {
				title: "Verso",
				titleVerbose: "Printed in Fliperature Verso",
				id: "verso",
				shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzY3ODk1OTAzNDUzOA==",
			},
			recto: {
				title: "Recto",
				titleVerbose: "Printed in Fliperature Recto",
				id: "recto",
				shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzY3ODk1OTA2NzMwNg==",
			}
		}
	}
}

const withGST = amount => amount * 1.15;

export const getProduct = (id) => (products[id]);
export const getVariant = (id, variant) => (products[id].variants[variant]);

export const getVariantAvailable = (id, variant) => (products[id].variants[variant].shopifyData.available || products[id].variants[variant].shopifyData.availableForSale);

export const getProductPrice = (id, variant) => {
	let prod = getVariant(id, variant);
	return withGST(parseFloat(prod.shopifyData.price));
}

export const getKeteTotal = () => checkout ? withGST(parseFloat(checkout.lineItemsSubtotalPrice.amount)) : 0;

export const getCheckoutUrl = () => checkout ? checkout.webUrl : null;

export const initShop = async () => {
	console.log("Shop initialises");
	let existingCheckoutId = localStorage.getItem("FLIP::checkout-id", null);
	let co;
	if(existingCheckoutId){
		try {
			console.log("Loading existing checkout...");
			co = await client.checkout.fetch(existingCheckoutId);
			console.log("Got existing checkout", existingCheckoutId);
			if(co.completedAt){
				console.log("Detected completed checkout, forcing creation of new...");
				localStorage.removeItem("FLIP::checking-out");
				co = null;
			}
		} catch (error) {
			console.error("Error loading existing checkout!", error);
			reportShopError(error);
		}
	}
	if(!co){
		try {
			console.log("Creating new checkout...");
			co = await client.checkout.create();
			console.log("Created new checkout", co.id);
			localStorage.setItem("FLIP::checkout-id", co.id);
		} catch (error) {
			console.error("Error creating checkout!", error);
			reportShopError(error);
		}
	}

	let shopifyProducts = await client.product.fetchAll();
	for(let id in products){
		let p = shopifyProducts.find(el => el.id === products[id].shopifyId);
		products[id].shopifyData = p;
		if(!p){
			reportShopError(new Error(`Failed to find shopify product entry for ${id} (${products[id].shopifyId})`));
			continue;
		}
		console.log(`Successfully loaded product info for ${id}`);
		for(let variant in products[id].variants){
			let v = p.variants.find(el => el.id === products[id].variants[variant].shopifyId);
			if(!p){
				reportShopError(new Error(`Failed to find shopify product variant entry for ${id}/${variant} (${products[id].variants[variant].shopifyId})`));
				continue;
			}
			products[id].variants[variant].shopifyData = v;
			console.log(`Successfully loaded product variant info for ${id}/${variant}`);
		}
	}
	console.log({products});

	refreshState(co);


	let wasCheckingOut = localStorage.getItem("FLIP::checking-out");
	if(wasCheckingOut){
		let now = moment();
		let then = moment(wasCheckingOut);
		if(then){
			let minsPassed = now.diff(then, 'minutes');
			if(minsPassed >= 10){
				console.log("Detected old checking out time -- removing.");
				localStorage.removeItem("FLIP::checking-out");
			} else {
				console.log("Detected order was recently at checkout -- restarting poll");
				pollCheckoutCompletion(true);
			}
		}
	}
}

const reportShopError = (error) => {
	console.error(`Store error! ${error.message}`);
	shopError = error;
}

export const webPortalUrl = () => checkout ? checkout.webUrl : null;

export const addToKete = async (product, variant, quantity) => {
	const lineItemsToAdd = [
		{
			variantId: products[product].variants[variant].shopifyId,
			quantity,
		}
	];
	try {
		let co = await client.checkout.addLineItems(checkout.id, lineItemsToAdd);	
		refreshState(co);
	} catch (error) {
		console.log(error);
		console.error("Error in addToKete. Details above.");
		return { error: "unknown" }
	}
	return { success: true };
}

export const editLineItemQuantity = async (id, quantity) => {
	const lineItemsToUpdate = [
		{ id, quantity }
	];

	console.log({lineItemsToUpdate});

	let co = await client.checkout.updateLineItems(checkout.id, lineItemsToUpdate);
	console.log("editLineItemQuantity", co.lineItems);
	refreshState(co);
	return { success: true }
}

export const removeLineItem = async (id) => {
	const lineItemsToRemove = [ id ];

	let co = await client.checkout.removeLineItems(checkout.id, lineItemsToRemove);
	console.log("removeLineItem", co.lineItems);
	refreshState(co);
	return { success: true }
}

let polling = false;

export const pollCheckoutCompletion = async (preventCheckoutTimeReset) => {
	if(polling){
		return;
	}
	let cancelled = false;
	let run = async () => {
		polling = true;
		let finished = false;
		if(!preventCheckoutTimeReset){
			localStorage.setItem("FLIP::checking-out", moment().format());	
		}
		while(!finished){
			if(cancelled){
				console.log("pollCheckoutCompletion cancelled");
				break;
			}
			console.log("Polling checkout complete...");
			let co = await client.checkout.fetch(checkout.id);
			if(co.completedAt){
				finished = true;
				console.log("Detected checkout completion! Nice. Creating new checkout.");
				let newCo = await client.checkout.create();
				localStorage.removeItem("FLIP::checking-out");
				incrementCheckoutComplete().dispatch();
				refreshState(newCo);
				break;
			} else {
				await wait(2000);
			}
		}
		polling = false;
	}
	run();
	return () => cancelled = true;
}

const refreshState = co => {
	checkout = co || checkout;
	let loaded = true;
	let available = checkout && !shopError;
	console.log({checkout});
	let lineItems = [];
	if(checkout){
		checkout.lineItems.forEach(li => {
			let product = null;
			let variant = null;
			for(let id in products){
				for(let va in products[id].variants){
					if(products[id].variants[va].shopifyId === li.variant.id){
						product = id;
						variant = va;
					}
				}
			}
			if(product && variant){
				lineItems.push({
					product, variant, quantity: li.quantity, id: li.id,
				});
			}
		});
	}

	setKete({
		loaded,
		available,
		lineItems
	}).dispatch();
	// Dispatch any redux about cart state.
}

