import { useRef, useState, useEffect, useCallback } from 'react'
import Callbacks from "./../../Utils/Callbacks";

const localStorageCallbacks = new Callbacks();

const useLocalStorage = (key, defaultValue, type, options={}) => {
	const storageKey = `useLocalStorage::${key}`

	const getValue = useCallback(() => {
		let v = window.localStorage.getItem(storageKey, null)
		if(v === undefined || v === null){
			v = defaultValue;
		} else {
			switch (type) {
				case "object":
					try {
						v = JSON.parse(v);
						if(v && options.mergeWithDefault){
							v = Object.assign({}, defaultValue, v);
						}
					} catch (error) {
						console.warn(`Error parsing localStorage object ${v}`);
						v = defaultValue;
					}
				break;
				case 'boolean':
					v = v === 'true'
					break
				case 'number':
					v = parseFloat(v)
					break
				case 'integer':
					v = parseInt(v)
					break
				default:
					break
			}
		}
		return v
	}, [storageKey, defaultValue, type, options.mergeWithDefault]);

	const startingValue = useRef(getValue());
	const [value, setValue] = useState(startingValue.current)

	useEffect(() => {
		return localStorageCallbacks.on(key, value => {
			setValue(value)
		});
	}, [key])

	const setLocalStorageValue = v => {
		if (v === null || v === undefined) {
			window.localStorage.removeItem(key)
		} else {
			let savedValue = v;
			switch(type){
				case "object":
					if(options.mergeWithDefault){
						v = Object.assign({}, defaultValue, v);
					}
					savedValue = JSON.stringify(v);
				break;
				default:
				break;
			}
			window.localStorage.setItem(storageKey, savedValue)
		}
		setValue(v)
		localStorageCallbacks.dispatch(key, v);
	}

	return [value, setLocalStorageValue]
}

export default useLocalStorage

