import { createStore, combineReducers } from "redux";
import ui from "./UI";
import kete from "./Kete";
import location from "./Location";

const store = createStore(combineReducers({
	ui,
	kete,
	location,
}), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;