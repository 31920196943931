import { useState, useEffect } from "react"
import Callbacks from "./../../Utils/Callbacks";

const dpiCallbacks = new Callbacks();

// From https://stackoverflow.com/a/29653772
window.matchMedia('screen and (min-resolution: 2dppx)').addListener(function(e) {
	console.log("dpi changes!", window.devicePixelRatio);
	dpiCallbacks.dispatch("dpi", window.devicePixelRatio);
});

const useDPI = () => {

	const [ dpi, setDpi ] = useState(window.devicePixelRatio);

	useEffect(() => {
		return dpiCallbacks.on("dpi", dpi => setDpi(dpi))
	}, [])

	return dpi;
}

export default useDPI;