import React, { useState } from "react";
import { TouchScroll, Button, LoadWheel } from "./../../UI";
import { PDFDocument, degrees } from 'pdf-lib'
import { handleUploadPDF, downloadPDF } from "./../../Utils/PDFUtils";

const Tools = () => {

	const [ loading, setLoading ] = useState(false);
	const [ message, setMessage ] = useState(null);
	
	return <TouchScroll className="page-overflow-container">
		<h1>Tools for Friends</h1>
		<div style={{
			maxWidth: "500px",
		}}>
			<p>PDFs are sticky as, hopefully these tools can help. If you have any issues, requests for changes, or new tool ideas, feel free to <a href="mailto:digl@fliperature.co.nz">get in touch</a>.</p>
		</div>
		<div style={{
			padding: "6px",
		}}>
			<h3>Four-Up</h3>
			<p>Tile a PDF four times across a single page.</p>
			{
				loading ? <>
					<div style={{
						width: "48px",
						height: "48px",
						position: "relative"
					}}>
						<LoadWheel />
					</div>
				</> : <>
					<div style={{
						position: 'relative',
						display: "inline-flex",
					}}>
						<Button label="Generate Four-Up" />
						<input style={{
							position: "absolute",
							inset: "0px",
							opacity: 0,
							cursor: "pointer",
						}} type="file" onChange={async evt => {
							if(evt.target.files.length > 0){
								setLoading(true)
								setMessage(null);
								let data = await handleUploadPDF(evt);
								if(data){
									const { editable, fileName } = data;
									const generatedQuad = await PDFDocument.create()
									const pages = editable.getPages();
									for(let i = 0; i<pages.length; i++){
										const { width, height } = pages[i].getSize();
										const { angle } = pages[i].getRotation();
										const newPage = generatedQuad.addPage([ width * 2, height * 2]);
										const [ embeddedPage ] = await generatedQuad.embedPdf(editable, [i]);
										newPage.drawPage(embeddedPage, { x: 0, y: 0 })
										newPage.drawPage(embeddedPage, { x: width, y: 0 })
										newPage.drawPage(embeddedPage, { x: width, y: height })
										newPage.drawPage(embeddedPage, { x: 0, y: height })
										// This is a fliperature-specific fix
										newPage.setRotation(degrees(angle));
									}

									await downloadPDF(generatedQuad, `(4-up) ${fileName}`);
								}
								setMessage("Done! Your download should start automatically.");
								setLoading(false)
							}
						}} />
					</div>
					{
						message && <p><i>{message}</i></p>
					}
				</>
			}
		</div>
	</TouchScroll>

}

export default Tools;