import React from "react";
import "./Mouseable.css";

class Mouseable extends React.Component {

	state = {
		hovered: false,
		held: false,
		holdStartX: -1,
		holdStartY: -1,
	}

	lastReportedDragX = -1;
	lastReportedDragY = -1;

	componentWillUnmount(){
		this.unlistenNativeEvents();
	}

	handleMouseMove = evt => {
		let { onDragged } = this.props;
		let { held, holdStartX, holdStartY } = this.state;

		if(onDragged && held){
			evt.preventDefault();
			let x = evt.clientX;
			let y = evt.clientY;
			onDragged({
				x,
				y,
				dx: this.lastReportedDragX - x,
				dy: this.lastReportedDragY - y,
				startX: holdStartX,
				startY: holdStartY,
			});
			this.lastReportedDragX = x;
			this.lastReportedDragY = y;
		}
	}

	handleMouseUp = evt => {
		let { disabled, onHeld, onMouseUp } = this.props;
		let { held } = this.state;
		if(disabled){
			return;
		}
		if(onHeld && held){
			onHeld(false);
		}
		if(onMouseUp){
			onMouseUp(evt);
		}
		this.setState({held: false});
		this.unlistenNativeEvents();
	}

	unlistenNativeEvents = () => {
		if(this.shouldUnsubNativeEvents){
			window.removeEventListener("mousemove", this.handleMouseMove, true);
			window.removeEventListener("mouseup", this.handleMouseUp, true);
			this.shouldUnsubNativeEvents = false;
		}
	}

	render(){
		let {
			disabled,
			onRef,
			onMouseDown,
			onMouseEnter, onMouseLeave, onHovered, onClick, onHeld, onMouseMove,
			releaseOnExit,
			className,
			heldClassName,
			hoveredClassName,
			fancyStyle
		} = this.props;
		let props = Object.assign({}, this.props);
		let { hovered, held } = this.state;
		props.style = Object.assign({},
			{
				cursor: disabled ? null : "pointer"
			},
			props.style,
			hovered ? props.hoveredStyle : null,
			held ? props.heldStyle : null,
		);

		delete(props.disabled);
		delete(props.heldStyle);
		delete(props.hoveredStyle);
		delete(props.onHeld);
		delete(props.onHovered);
		delete(props.onRef);
		delete(props.onMouseEnter);
		delete(props.onMouseDown);
		delete(props.onMouseUp);
		delete(props.onMouseLeave);
		delete(props.onMouseMove);
		delete(props.onDragged);
		delete(props.onClick);
		delete(props.releaseOnMouseLeave);
		delete(props.className);
		delete(props.heldClassName);
		delete(props.hoveredClassName);
		delete(props.fancyStyle);

		let cl = [
			(className || ""),
			(held ? heldClassName || "" : ""),
			(hovered ? hoveredClassName || "" : ""),
			held ? "held" : "",
			hovered ? "hovered" : "",
		];
		if(fancyStyle){
			if(Array.isArray(fancyStyle)){
				fancyStyle.forEach(f => cl.push(`mouseable-${f}`));
			} else {
				cl.push(`mouseable-${fancyStyle}`);
			}
		}
		cl = cl.join(" ");
		return <div
			ref={onRef}
			className={cl}
			onMouseEnter={evt => {
				if(disabled){
					return;
				}
				if(onMouseEnter){
					onMouseEnter(evt);
				}
				if(onHovered){
					onHovered(true);
				}
				this.setState({hovered: true});
			}}
			onMouseDown={evt => {
				if(disabled){
					return;
				}
				if(onMouseDown){
					onMouseDown(evt);
				}
				if(onHeld && !held){
					onHeld(true);
				}
				window.addEventListener("mousemove", this.handleMouseMove, true);
				window.addEventListener("mouseup", this.handleMouseUp, true);
				this.shouldUnsubNativeEvents = true;
				let x = evt.nativeEvent.clientX;
				let y = evt.nativeEvent.clientY;
				this.setState({
					held: true,
					holdStartX: x,
					holdStartY: y,
				});
				this.lastReportedDragX = x;
				this.lastReportedDragY = y;
			}}
			onMouseLeave={evt => {
				if(onMouseLeave){
					onMouseLeave(evt);
				}
				if(disabled){
					return;
				}
				if(releaseOnExit){
					this.setState({held: false});
					this.unlistenNativeEvents();
				}
				this.setState({hovered: false});
				if(onHeld && held){
					onHeld(false);
				}
				if(onHovered && hovered){
					onHovered(false);
				}
			}}
			onMouseMove={evt => {
				if(onMouseMove){
					onMouseMove(evt);
				}
				if(disabled){
					return;
				}
			}}
			onClick={evt => {
				if(disabled){
					return;
				}
				this.setState({held: false});
				if(onClick){
					onClick(evt);
				}
			}}
			{...props}
		/>
	}
}

Mouseable.defaultProps = {
	releaseOnMouseLeave: true,
}

export default Mouseable;