import React, { Fragment } from "react";
import { TouchScroll } from "./../../UI";

class About extends React.Component {

	renderResource = (resource, i) => {
		return <div style={{
			paddingLeft: "18px",
			position: "relative",
		}} key={i}>
			<div style={{
				backgroundColor: "#2C2C2C",
				position: "absolute",
				top: "9px",
				left: "0px",
				width: "6px",
				height: "6px",
				borderRadius: "3px",
			}} />
			<h5><a href={resource.link} target="_blank" rel="noopener noreferrer">{ resource.title }</a></h5>
			<p style={{fontWeight: "600"}}>{resource.author}</p>
			{
				resource.description
			}
		</div>
	}

	render(){
		let resources = [
			{
				title: "Frankenstein Turns 200 and Becomes Required Reading for Scientists",
				author: "Sidney Perkowitz (LA Review of Books)",
				description: <Fragment>
					<p><i>Frankenstein: Annotated for Scientists, Engineers and Creators of All Kinds</i> was published for Frankenstein’s bicentennial. The book itself uses the Frankenstein narrative to prompt conversations about creativity and responsibility between scientists, scholars, students, and the public. The review touches on the histories of Mary Shelley and her story, the relationship between Shelley and contemporaneous science, feminist interpretations of the novel, and the potential implications of the story for young scientists. As such it provides an accessible foray into the diversity of thought and writing available in relation to Shelley’s classic work of science fiction.</p>
				</Fragment>,
				link: "https://lareviewofbooks.org/article/frankenstein-turns-200-and-becomes-required-reading-for-scientists/",
			},
			{
				title: "Freud, Frankenstein and our fear of robots: projection in our cultural perception of technology",
				author: "Michael Szollosy",
				description: <Fragment>
					<p>Michael Szollosy draws an ancestral link between Frankenstein and his monster and the robots, cyborgs, and AI that haunt contemporary pop-culture. These monstrous beings, Szollosy argues, are a receptacle for our anxieties; not just about how we make technology, but about how technology makes us. Encased in Frankenstein’s Monster (and the robots that follow) are fears about how our own commitments to rationality, productivity, and dispassionate science transform us into something unfeeling.</p>
				</Fragment>,
				link: "https://link.springer.com/article/10.1007/s00146-016-0654-7"
			},
			{
				title: "Frankenstein (Critical Posthumanism)",
				author: "Elaine Graham",
				description: <Fragment>
					<p>Elain Graham offers an accessible introduction to Frankenstien which compares the story to other mythical tales (Prometheus and Doctor Faustus) and situates the monster in a lineage of posthuman figures like David from AI, Cmdr. Data from Star Trek: Next Generation, and Roy Batty from Blade Runner all of whom disrupt the audience’s ideas of what it means to be human. Graham also notes how cinematic depictions of the monster have denied the character a voice and point of view.</p>
				</Fragment>,
				link: "https://criticalposthumanism.net/frankenstein/",
			},
			{
				title: "Dialectic of Fear",
				author: "Franco Moretti (New Left Review)",
				description: <Fragment>
					<p><i>“A metaphor gets up and walks”</i></p>
					<p>Moretti’s reading of Frankenstein (& Dracula) is dense but offers an introduction to key Marxist & Freudian ideas through a look at the dialectical relationship between the monster & the proletariat of Shelley’s time. And an analysis of the role of private property & the family structure in a reading of Frankenstein and how this is produced by the peculiar fable-like-narrative that Shelley employs (p.69-73).</p>
				</Fragment>,
				link: "https://newleftreview.org/issues/i136/articles/franco-moretti-the-dialectic-of-fear",
			}
		];
		return <TouchScroll className="page-overflow-container">
			<div className="page-overflow-content">
				<div style={{maxWidth: "600px", marginBottom: "36px"}}>
					<h1 style={{marginTop: "0px"}}>Dive Deeper</h1>
					<h5>Good thoughts about good books.</h5>
					<p>Reading a book doesn’t have to stop at the last page. If you’re looking for resources to fuel the contemplation of your latest Fliperature title, try starting with some of the essays we’ve collated below.</p>
					<hr />
					<h3>Frankenstein; or, The Modern Prometheus</h3>
					{
						resources.map(this.renderResource)
					}
					<p style={{marginTop: "24px"}}><i>More resources on Frankenstein can be found at </i><a href={"https://frankensteinmeme.com/"} target="_blank" rel="noopener noreferrer">Frankenstein Meme</a></p>
				</div>
			</div>
		</TouchScroll>
	}

}

export default About;