import { PDFDocument } from 'pdf-lib'

export const handleUploadPDF = evt => new Promise((resolve) => {
	if(evt.target.files.length > 0){
		const file = evt.target.files[0];
		const reader = new FileReader();
		reader.onload = async () => {
			const arrayBuffer = reader.result;

			const loadingTask = await pdfjs.getDocument(arrayBuffer)
			const pdfjsDoc = await loadingTask.promise;
			const pdflibDoc = await PDFDocument.load(arrayBuffer)

			resolve({ fileName: file.name, editable: pdflibDoc, displayable: pdfjsDoc });
		}
		reader.readAsArrayBuffer(file);
	} else {
		resolve(null);
	}
})

export const downloadPDF = async (pdfDoc, fileName) => {
	const link = document.createElement('a');
	link.href = await pdfDoc.saveAsBase64({ dataUri: true }); //window.URL.createObjectURL(blob);
	link.download = fileName;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


let pdfjs;

const loadPdfJs = async () => {
	pdfjs = await import('pdfjs-dist/build/pdf');
	const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
	pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
}
loadPdfJs();

export const getPdfJs = () => pdfjs;