import pages from "./Pages";
import { setPageAndArgs, setPage as _setPage, setArgs as _setArgs } from "./../Redux/Location";
import deepEquals from "deep-equals";

let currentPage = "home";
let currentArgs = {};

let basepaths = [
	"http://localhost:3000",
	"http://localhost:3001",
	"https://fliperature.co.nz",
	"https://fliperature.com"
];

window.addEventListener('popstate', evt => {
	setFromUrl();
});

export const setPage = (page, args={}, preventPush=false) => {
	let pageInfo = pages.find(p => p.page === page);
	if(!pageInfo){
		console.error(`Failed to call setPage -- page "${page}" doesn't exist`);
		return;
	}
	let bp = getBasepath();
	let argsKeys = Object.keys(args);
	let newUrl = `${bp}/${pageInfo.urlStub}${argsKeys.length > 0 ? `?${argsKeys.map(k => `${k}=${encodeURIComponent(args[k])}`).join("&")}` : ""}`;
	if(!preventPush){
		window.history.pushState({}, `Fliperature - ${pageInfo.name}`,  newUrl);	
	}
	// window.location.href = newUrl;
	let dispatchPage = currentPage !== page;
	let dispatchArgs = !deepEquals(args, currentArgs);
	if(dispatchPage && dispatchArgs){
		setPageAndArgs(page, args).dispatch();	
	} else if (dispatchPage){
		_setPage(page).dispatch();
	} else if (dispatchArgs){
		_setArgs(args).dispatch();
	}
	currentPage = page;
	currentArgs = args;
}

export const setArgs = (args, preventPush) => {
	let newArgs = Object.assign({}, currentArgs, args);
	for(let key in args){
		if(args[key] === null || args[key] === undefined){
			delete(newArgs[key]);
		}
	}
	setPage(currentPage, newArgs, preventPush);
}

const getBasepath = () => {
	let url = window.location.href;
	return basepaths.find(b => url.includes(b));
}

export const initFromUrl = () => setFromUrl();

export const setFromUrl = () => {
	let url = window.location.href;
	let bp = getBasepath();
	if(!bp){
		console.error(`Failed to find basepath for url ${url}`);
	}
	let extra = url.substring(bp.length, url.length);
	if(extra.length > 0 && extra[0] === "/"){
		extra = extra.substring(1, extra.length);
	}

	let components = extra.split("/");

	let page;
	let args = {};
	if(components.length > 0){
		let c2 = components[0].split("?");
		page = pages.find(p => p.urlStub === c2[0]);
		if(!page){
			page = pages[0].page;
		} else {
			page = page.page;
		}
		if(c2.length > 1){
			let c3 = c2[1].split("&");
			for(let i = 0; i<c3.length; i++){
				if(c3[i].length > 0){
					let c4 = c3[i].split("=");
					if(c4.length > 1){
						switch(c4[1]){
							case "true":
								args[c4[0]] = true;
							break;
							case "false":
								args[c4[0]] = false;
							break;
							default:
								args[c4[0]] = decodeURIComponent(c4[1]);
							break;
						}
					} else {
						args[c4[0]] = true;
					}
				}
			}
		}
	} else {
		page = pages[0];
	}

	setPage(page, args, true);
}