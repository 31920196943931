import React from "react";
import asPropsResponder from "./../Utils/PropsResponder"

class TimeoutFragment extends React.Component {

	state = {
		visible: false
	}

	componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout);
		}
	}

	onPropsChange = (propName, props) => {
		let { delay, visible } = props;
		if(this.timeout){
			clearTimeout(this.timeout);
			this.timeout = null;
		}
		if(visible){
			this.setState({
				visible: true,
			});
		} else if(this.state.visible) {
			this.timeout = setTimeout(() => {
				this.setState({visible: false})
			}, delay);
		}
	}

	render(){
		let { children } = this.props;
		let { visible } = this.state;
		if(!visible){
			return null;
		}
		return children;
	}

}

TimeoutFragment.defaultProps = {
	delay: 1000,
}

export default asPropsResponder(TimeoutFragment, "visible", {onMount: true});