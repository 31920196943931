
let setSizeCall = () => console.log("Responsive::setSizeCall not initialised!");

const sizes = {
	phone: { size: 576 },
	tablet: { size: 768 },
	large: { size: 992 },
	extraLarge: { size: 1200 },
}
const sizeArray = Object.values(sizes);
const sizeKeys = Object.keys(sizes);
sizeKeys.forEach((k, i) => {
	let s = sizes[k];
	s.name = k;
	s.largerThan = other => s.size > sizes[other].size;
	s.smallerThan = other => s.size < sizes[other].size;
	s.largerOrEqualTo = other => s.size >= sizes[other].size;
	s.smallerOrEqualTo = other => s.size <= sizes[other].size;
	s.is = size => size === k;
	s.nextSmallest = (i - 1) >= 0 ? sizes[sizeKeys[i - 1]] : null;
	s.nextLargest = (i + 1) < sizeKeys.length ? sizes[sizeKeys[i + 1]] : null;
});

let currentSize = null;

const handleSizeChange = () => {
	let { innerWidth } = window;
	let size = sizeArray[0];
	for(let i = 1; i<sizeArray.length; i++){
		if(innerWidth > sizeArray[i].nextSmallest.size){
			size = sizeArray[i];
		} else {
			break;
		}
	}
	if(size !== currentSize){
		currentSize = size;
		setSizeCall(size);
	}
}

export const mapMedia = (media, map) => {
	if(map[media.name]){
		return map[media.name];
	}
	let lowest = sizeArray[sizeArray.length - 1];
	let highest = sizeArray[0];
	for(let key in map){
		if(sizes[key].size < lowest.size){
			lowest = sizes[key];
		}
		if(sizes[key].size > highest.size){
			highest = sizes[key];
		}
	}
	if(media.smallerThan(lowest.name)){
		return map[lowest.name];
	}
	if(media.largerThan(highest.name)){
		return map[highest.name];
	}
	return media.nextLargest;
};

export const initResponsive = (onSize) => {
	setSizeCall = onSize;
	window.addEventListener("resize", handleSizeChange);
	handleSizeChange();
}
