import React from "react";
import loop from "./../../Utils/Loop";
import { Image } from "./../../UI";
import { connect } from "react-redux";

const mapStateToProps = state => ({
	siteDark: state.ui.siteDark,
});

const VIDEO_FPS = 24;
const INVERSE_VIDEO_FPS = 1 / VIDEO_FPS;
const VIDEO_DURATION = 2;

class FlipVideo extends React.Component {

	componentDidMount(){
		this.unsubLoop = loop(this.frame, 24);
	}

	componentWillUnmount(){
		if(this.unsubLoop){
			this.unsubLoop();
		}
	}

	frame = (dt) => {
		let { siteDark } = this.props;
		let direction = siteDark ? 1 : -1;
		if(!this.videoRef){
			return;
		}

		let newT = (this.videoRef.currentTime * VIDEO_FPS + direction) * INVERSE_VIDEO_FPS;// + FRAME_TIME * direction;

		newT = Math.max(newT, 0);
		newT = Math.min(newT, VIDEO_DURATION);
		if(this.videoRef.currentTime !== newT){
			this.videoRef.currentTime = newT;
		}
	}


	render(){
		return <Image {...this.props} elementRef={ref => this.videoRef = ref} />
	}

}

export default connect(mapStateToProps)(FlipVideo);