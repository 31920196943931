import GenericReducer from "./Utils/ReduxGeneric";
import quick from "./Utils/ReduxQuick";

const g = new GenericReducer("kete", {
	loaded: false,
	available: true,
	lineItems: [],
}, quick);

export const setKete = g.allow("set");

export default g.apply();