import listen from "./ReduxListen";

const ios = {};
let initialiseListeners = {};

export const onInitialisation = (io, callback) => {
	if(!ios[io] || !ios[io].stateAtInitialisation){
		if(!initialiseListeners[io]){
			initialiseListeners[io] = [];
		}
		initialiseListeners[io].push(callback);
	} else {
		callback(ios[io].stateAtInitialisation);
	}
}

export const initialisePersistence = async (store, config) => {
	Object.keys(config).forEach(io => {
		ios[io] = Object.assign({}, ios[io], {
			store,
			storageCalls: config[io],
			isLoading: false,
			hasLoaded: false,
		});
		
		setTimeout(() => begin(io), 50);
	});
}

export const reloadPersistence = io => {
	if(!Array.isArray(io)){
		io = [io];
	}
	io.forEach(begin);
}

export const persistentReducer = (io, pathToReducer, reducer, paths=[[]]) => {
	
	if(!ios[io]){
		ios[io] = {};
	}
	ios[io].pr = { pathToReducer, paths };

	return (state, action) => {
		if(action.type === `__REDUX_PERSISTENCE_OVERRIDE_${io}`){
			let { existing } = action;
			let newState = Object.assign({}, state);
			let o = newState;
			for(let i = 0; i<existing.length; i++){
				o = newState;
				let { path, value } = existing[i];

				path = path.slice(pathToReducer.length, path.length);
				if(path.length === 0){
					newState = value;
				} else {
					for(let j = 0; j<path.length; j++){
						let p = path[j];
						if(j === path.length - 1){
							o[p] = value;
						} else {
							o[p] = Object.assign({}, o[p]);
							o = o[p];
						}
					}
				}
			}
			ios[io].stateAtInitialisation = newState;
			if(initialiseListeners[io]){
				initialiseListeners[io].forEach(cb => cb(newState));
			}
			return newState;
		} else {
			return reducer(state, action);	
		}
	}
}

const begin = async (io) => {
	let { storageCalls, isLoading, store } = ios[io];
	if(!isLoading){
		ios[io].isLoading = true;
		try {
			let existing = await storageCalls.loadAll();
			console.log(`Loaded initial state for ${io}`, existing);
			store.dispatch({
				type: `__REDUX_PERSISTENCE_OVERRIDE_${io}`,
				existing
			});

			if(ios[io].unlisten){
				ios[io].unlisten();
			}

			ios[io].unlisten = listenForChanges(io);			
		} catch (error) {
			console.error(error);
			console.log(`ReduxPersistence encountered error beginning io ${io}: ${error.message}. Full error above.`);
		} finally {
			ios[io].isLoading = false;
		}
		
	} else {
		console.log(`ReduxPersistence begin call for ${io} was blocked -- IO is already loading.`);
	}
}

const listenForChanges = (io) => {

	let { pr, store, storageCalls } = ios[io];
	if(!pr){
		console.warn(`ReduxPersistence io "${io}" does not have an associated reducer.`);
		return;
	}

	let structure = {};
	
	let { paths, pathToReducer } = pr;
	paths = paths.map(p => [...pathToReducer, ...p]);
	for(let i = 0; i <paths.length; i++){
		let path = paths[i];
		let o = structure;
		let finalPath = [];
		let finalPathWildcardIndices = {};
		for(let j = 0; j<path.length; j++){
			let p = path[j];

			if(p[0] === "*"){
				let wc = p.substring(1, p.length);
				finalPathWildcardIndices = Object.assign({}, finalPathWildcardIndices, {
					[wc]: finalPath.length,
				});
				finalPath = finalPath.slice();
				finalPath.push("***");
			} else {
				finalPath.push(p);
			}

			if(j === path.length - 1){
				let wcIndices = Object.assign({}, finalPathWildcardIndices);
				let savedPath = finalPath.slice();
				o[p] = (newValue, oldValue, context) => {
					
					Object.keys(context).forEach(key => {
						if(wcIndices[key]){
							savedPath[wcIndices[key]] = context[key];
						} else {
							console.warn(`ReduxPersistence: finalPath missing wildcard keys for ${key}`);
						}
					});
					let toSave = { value: newValue, /*context, */path: savedPath };
					if(newValue === null || newValue === undefined){
						storageCalls.remove(savedPath.join("/"));
					} else {
						storageCalls.save(savedPath.join("/"), toSave);
					}
				}
			} else {
				if(!o[p]){
					o[p] = {};
				}
				o = o[p];
			}
		}
	}

	return listen(store, structure);
}


