import { Home, HomeBackground } from "./Home";
import { Shop, ShopBackground } from "./Shop";
import { About } from "./About";
import { Resources } from "./Resources";
import { Fliperator } from "./Fliperator";
import { Tools } from "./Tools";

export default [
	{
		page: "home",
		urlStub: "",
		name: "Home",
		component: Home,
		backgroundComponent: HomeBackground,
		theme: "dark",
	},
	{
		page: "shop",
		name: "Shop",
		urlStub: "shop",
		component: Shop,
		backgroundComponent: ShopBackground,
		theme: "light",
	},
	{
		page: "resources",
		urlStub: "resources",
		name: "Resources",
		component: Resources,
		backgroundComponent: ShopBackground,
		theme: "light",
	},
	{
		page: "about",
		urlStub: "about",
		name: "About",
		component: About,
		backgroundComponent: ShopBackground,
		theme: "light",
	},
	{
		page: "fliperator",
		urlStub: "fliperator",
		hidden: true,
		hideKete: true,
		name: "Fliperator",
		component: Fliperator,
		backgroundComponent: ShopBackground,
		theme: "light",
	},
	{
		page: "tools",
		urlStub: "tools",
		hidden: true,
		hideKete: true,
		name: "Tools",
		component: Tools,
		backgroundComponent: ShopBackground,
		theme: "light",
	},
]