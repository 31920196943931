import React from "react";
import DotLoader from "react-spinners/DotLoader";
import classnames from "classnames";
import "./LoadWheel.css";

class LoadWheel extends React.Component {

	state = {
		entered: false
	}

	componentDidMount(){
		this.mounted = true;
		let { entranceDelay } = this.props;
		this.entranceTimeout = setTimeout(() => this.setState({entered: true}), entranceDelay);
	}

	componentWillUnmount(){
		clearTimeout(this.entranceTimeout);
	}

	render(){
		let { entered } = this.state;
		let { color, size } = this.props;
		return <div className={classnames("load-wheel-container", {entered})} style={{
			position: "absolute",
			top : '50%',
			left : '50%',
			transform: "translate(-50%, -50%)",
		}}>
			<DotLoader size={size} color={color} loading />
		</div>
	}

}

LoadWheel.defaultProps = {
	color: "#000",
	size: 24,
	entranceDelay: 250,
}

export default LoadWheel;