import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import store from "./Redux";
import { initialiseQuick } from "./Redux/Utils/ReduxQuick";
import { initShop } from "./Backend/Shopify";
import { initFromUrl } from "./Pages/PageHandler";
import { initResponsive } from './Utils/Responsive';
import { setMedia } from './Redux/UI';
// import * as serviceWorker from './serviceWorker';

initialiseQuick(store);
initShop();
initFromUrl();
initResponsive(size => setMedia(size).dispatch());

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
