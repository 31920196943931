import React from "react";
import { Button, Modal } from "./../UI";

let instance;
export const getConfirmer = () => instance;

class Confirmer extends React.Component {

	state = {
		open: false,
		details: {},
	}

	componentDidMount(){
		instance = this;
	}

	open = () => {
		this.setState({open: true});
	}

	ask = details => {
		this.setState({
			details,
			open: true
		});
		return new Promise(resolve => this.resolve = resolve);
	}

	handleChoice = accepted => {
		this.setState({
			open: false,
		});
		this.resolve(accepted);
	}

	render(){
		let { open, details } = this.state;
		let { title, body } = details;
		return <Modal open={open} style={{
				padding: "18px"
			}}
		>
			<p>{title}</p>
			{ body }
			<div style={{marginTop: "18px"}}>
				<Button style={{
					marginRight: "12px",
				}} theme="secondary" label="Cancel" onClick={() => this.handleChoice(false)} />
				<Button theme="secondary" label="Yes" onClick={() => this.handleChoice(true)} />
			</div>
		</Modal>
	}

}

export default Confirmer;