import React from "react";
import * as THREE from 'three';
import GLTFLoader from 'three-gltf-loader'
import loop from "./../../Utils/Loop";

const loader = new GLTFLoader();

let renderer, camera, scene;
let ALLOW_HIGH_DPI = true;
let cubes;
const main = (canvas, options) => {
	ALLOW_HIGH_DPI = options.allowHighDpi;
	renderer = new THREE.WebGLRenderer({canvas, alpha: true});
	renderer.setClearColor( 0x000000, 1 ); // the default

	let fov = 40;
	let aspect = 2;
	let near = 0.1;
	let far = 1000;
	camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
	camera.position.z = 120;

	scene = new THREE.Scene();

	loader.load('./3d/monkey.gltf', function ( gltf ) {

		scene.add( gltf.scene );

	}, undefined, function ( error ) {

		console.error( error );

	} );

	{
	    let color = 0xFFFFFF;
	    let intensity = 1;
	    const light = new THREE.DirectionalLight(color, intensity);
	    light.position.set(-1, 2, 4);
	    scene.add(light);
	}

	let boxWidth = 1;
	let boxHeight = 1;
	let boxDepth = 1;
	let box = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);

	const makeInstance = (geo, color, x) => {
	    const mat = new THREE.MeshPhongMaterial({color});
	    const cube = new THREE.Mesh(geo, mat);
	    scene.add(cube);
	    cube.position.x = x;
	    return cube;
	}

	cubes = [
	    makeInstance(box, 0x44aa88,  0),
	    makeInstance(box, 0x8844aa, -2),
	    makeInstance(box, 0xaa8844,  2),
	];

	loop(rerender, -1, { returnAsFps: 60 });
}

const rerender = (dt, {time, pTime}) => {

	let canvas = renderer.domElement;
	let { clientWidth, clientHeight } = canvas;
	if(ALLOW_HIGH_DPI){
	    clientWidth *= window.devicePixelRatio || 1;
	    clientHeight *= window.devicePixelRatio || 1;   
	}
	if(clientWidth !== canvas.width || clientHeight !== canvas.height){
	    renderer.setSize(clientWidth, clientHeight, false);
	    camera.aspect = clientWidth / clientHeight;
	    camera.updateProjectionMatrix();   
	}

	// Run rerendering

	cubes.forEach((cube, i) => {
	    const speed = 0.01 + i * 0.005;
	    const rot = dt * speed;
	    cube.rotation.x += rot;
	    cube.rotation.y += rot;
	});

	//

	renderer.render(scene, camera);
}

class Preview3D extends React.Component {

	componentDidMount(){
		// Init THREE stuff.
		const wait = delay => new Promise(resolve => setTimeout(resolve, delay));
		const initialiseWhenReady = async () => {
		    while(!this.initialised){
		        if(this.canvasRef){
		            this.initialised = true;
		            main(this.canvasRef, {
		            	allowHighDpi: true,
		            });
		        }
		        await wait(1);
		    }
		}
		initialiseWhenReady();
	}

	render(){
		return <canvas ref={ref => this.canvasRef = ref} style={{
			position: "absolute",
			top: "0px",
			left: "0px",
			width: "100%",
			height: "100%"
		}} />
	}

}

export default Preview3D;