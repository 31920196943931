import React from "react";
import { TouchScroll, Button, Paginator } from "./../../UI";
import { setPage } from './../../Pages';
import { connect } from "react-redux";
import { mapMedia } from './../../Utils/Responsive';
import { setSplashIndex } from "./../../Redux/UI";
import classnames from "classnames";
import { cta, light, dark } from "./../../Colors";
import "./Home.css";

const mapStateToProps = state => ({
	media: state.ui.media,
	splashIndex: state.ui.splashIndex,
	backgroundDark: state.ui.backgroundDark,
});

class Home extends React.Component {

	renderSplash = index => {
		let { media, splashIndex } = this.props;
		let inner;
		switch(index){
			case 0:
				inner = <div style={Object.assign({
					position: "absolute",
					right: "0px",
				}, mapMedia(media, {
					phone: {
						top: "24px",
						width: "100%",
						textAlign: "center",
					},
					tablet: {
						top: "48px",
						width: "fit-content",
						maxWidth: "100%",
						textAlign: "right",
					}
				}))}
			>
				<h2 style={{
					top: "0px",
					fontSize: media.is("phone") ? "32px" : "48px",
					marginBottom: "0px",
					marginTop: "0px",
					lineHeight: media.is("phone") ? "44px" : "60px",
				}}><span style={{
					backgroundColor: "white",
					padding: "12px",
					paddingBottom: "6px",
				}}>Books that are</span><br/><span style={{
					backgroundColor: "white",
					padding: "12px",
					paddingBottom: "6px",
				}}>good in bed.</span></h2>
				<div style={{
					marginTop: "24px",
					maxWidth: media.is("phone") ? "100%" : "334px",
					float: "right",
					backgroundColor: "white",
					padding: "18px 12px",
					textAlign: "left",
				}}>
					<p style={{
						fontSize: "14px",
						lineHeight: "20px",
					}}><strong>Fliperature</strong> is a book format designed for comfortable reading while lying down.</p>
					<p>Read on one side of the book. When you reach the end, flip it and continue reading.</p>
				</div>
				<div>
					<Button
						style={{
							marginTop: "12px",
						}}
						label="Shop Online"
						icon="buy-online"
						onClick={() => setPage("shop")}
					/>
				</div>
			</div>
			break;
			case 1:
			default:
				inner = <div style={Object.assign({
					position: "absolute",
					right: "0px",
				}, mapMedia(media, {
					phone: {
						top: "24px",
						width: "100%",
						textAlign: "center",
					},
					tablet: {
						float: "right",
						top: "48px",
						width: "fit-content",
						maxWidth: "100%",
						textAlign: "right",
					}
				}))}
			>
				<h4 style={{
					fontSize: "16px",
				}}><span style={{backgroundColor: 'white', padding: "6px"}}>Available Now</span></h4>
				<div style={{backgroundColor: "white", padding: "6px"}}>
					<h2 style={{
						backgroundColor: "white",
						fontSize: media.is("phone") ? "30px" : "48px",
						marginBottom: "12px"
					}}>Frankenstein;</h2>
					<h3 style={{
						backgroundColor: "white",
						fontSize: media.is("phone") ? "24px" : "36px",
						marginTop: "12px",
						marginBottom: "12px",
					}}>or, The Modern<br />Prometheus</h3>
				</div>
				<Button
					style={{
						marginTop: "12px",
					}}
					label="Buy Online"
					icon="buy-online"
					onClick={() => setPage("shop", { product: "frankenstein" })}
				/>
			</div>
			break;
		}
		return <TouchScroll key={index} className={classnames("splash-container", {
				open: splashIndex === index,
				next: index > splashIndex,
				previous: index < splashIndex,
			})}>
			{ inner }
		</TouchScroll>
	}

	render(){
		let { media, splashIndex, backgroundDark, open } = this.props;
		let splashes = [0, 1];
		return <TouchScroll className="page-overflow-container">
			<div style={{width: "100%", height: "100%", position: "relative", overflow: "hidden"}}>
			{
				splashes.map(this.renderSplash)
			}
			</div>
			<Paginator
				speed={12}
				autoProceed={open}
				style={mapMedia(media, {
					phone: {
						position: "absolute",
						left: "50%",
						bottom: "20px",
						transform: "translateX(-50%)",
					},
					tablet: {
						position: "absolute",
						bottom: "30px",
						right: "36px",
					}
				})}
				pages={splashes}
				value={splashIndex}
				onChange={idx => setSplashIndex(idx).dispatch()}
				activeColor={cta}
				inactiveColor={backgroundDark ? light : dark}
			/>
		</TouchScroll>
	}

}

export default connect(mapStateToProps)(Home);