import React from "react";

class AboutBackground extends React.Component {

	render(){
		return <div style={{width: "100%", height: "100%", backgroundColor: "#F3F3F3"}} />
	}

}

export default AboutBackground;