import React from 'react';
import { Mouseable, Icon, TimeoutFragment } from "./UI";
import { dark, light } from "./Colors";
import { useSelector } from "react-redux";
import { setPage } from "./Pages";
import classnames from "classnames";
import { pages } from "./Pages";
import { Kete, Confirmer } from "./Singletons";
import { mapMedia } from "./Utils/Responsive";
import Nav from "./Nav";
import './App.css';


const App = () => {
	
	const pageName = useSelector(state => state.location.page);
	const media = useSelector(state => state.ui.media);
	const siteDark = useSelector(state => state.ui.siteDark);
	
	const page = pages.find(p => p.page === pageName);
	let { theme } = page;
	return <div className={classnames("app", media.name, siteDark ? "dark" : "light")}>
		{
			pages.map((p, i) => {
				let Page = p.component;
				let Background = p.backgroundComponent;
				let open = page.page === p.page;
				return <TimeoutFragment visible={open} key={i}>
					<div className={classnames("page-background", { open })} >
						<Background />
					</div>
					<div className={classnames("page-container", { open })} style={{
						position: "absolute",
						left: mapMedia(media, {
							phone: "18px",
							tablet: "140px",
							large: "140px",
							extraLarge: "160px",
						}),
						right: mapMedia(media, {
							phone: "12px",
							tablet: "3px"
						}),
						top: mapMedia(media, {
							phone: "100px",
							tablet: "60px",
						}),
						bottom: "15px",
					}}>
						<div
							className={classnames("page", { open })}
						>
							<Page open={open} />
						</div>
					</div>
				</TimeoutFragment>
			})
		}
		<Nav theme={theme} style={{
			position: "absolute",
			paddingTop: "6px",
			top: media.is("phone") ? "52px" : "15px",
			left: media.is("phone") ? "18px" : "35px",
		}} />
		<Mouseable key={`icon_${media.name}`} fancyStyle="pressable-light" style={
			mapMedia(media, {
			phone: {
				position: "absolute",
				top: `20px`,
				left: `10px`,
				width: `${466 * 0.3}px`,
				height: `${128 * 0.3}px`,
			},
			tablet: {
				position: "absolute",
				bottom: `20px`,
				left: `20px`,
				width: `${128 * 0.5}px`,
				height: `${466 * 0.5}px`,
			}
		})} onClick={() => setPage("home")}>
			<Icon
				style={{
					width: "100%",
					height: "100%",
					pointerEvents: "none",
				}}
				color={ mapMedia(media, {
					phone: dark,
					tablet: theme === "light" ? dark : light
				})}
				icon={mapMedia(media, { phone: "fliperature", tablet: "fliperature-vert" })}
			/>
		</Mouseable>
		<Kete hidden={page.hideKete} />
		
		<Confirmer />
	</div>
}

export default App;