import React from "react";
import { Mouseable, Icon } from "./../../UI";
import { toggleSiteDark } from "./../../Redux/UI";
import classnames from "classnames";
import { connect } from "react-redux";
import "./FlipButton.css";

const mapStateToProps = state => ({
	siteDark: state.ui.siteDark,
});

class FlipButton extends React.Component {

	render(){
		let { style, siteDark } = this.props;
		return <div style={style}>
			<Mouseable fancyStyle={["pressable", "pop-entrance"]} style={{
				// backgroundColor: "rgba(255, 255, 255, 0.3)",
				borderRadius: "75px",
				padding: "12px",
			}} onClick={(() => toggleSiteDark().dispatch())}>
				<div style={{
					verticalAlign: "middle",
					display: "inline-block",
					width: "24px",
					height: "24px",
					marginRight: "12px",
				}} className={classnames("flip-button-icon", { flipped: siteDark })}>
					<Icon style={{
						position: "absolute",
						top: '50%',
						left: '50%',
						width: "24px",
						height: "24px",
						transform: "translate(-50%, -50%)",
					}} icon="flip" />
				</div>
				<p style={{
					verticalAlign: "middle",
					display: "inline-block",
					fontSize: "18px",
					margin: "0px",
				}}>Flip</p>
			</Mouseable>
		</div>
	}

}

export default connect(mapStateToProps)(FlipButton);