import React from "react";
import withAnimationFrame from "react-animation-frame";

const animationMs = 16;

const getAnimationState = (data, animationName) => {
	if(animationName){
		if(!data || !data.animations || !data.animations[animationName]){
			throw new Error(`Missing animation data for ${animationName}`);
		}

		let startFrame = data.animations[animationName].from;
		let endFrame = data.animations[animationName].to;

		return {
			startFrame,
			endFrame,
			frame: startFrame,
			lastRenderedFrame: startFrame,
			resetFrame: true
		};
	}
	return null
}

const getSpriteState = (props) => {
	let { sprite } = props;
	if(!sprite){
		throw new Error("Sprite missing!");
	}
	let src = require(`./../assets/spritesheets/${sprite}.png`);
	let config = require(`./../assets/spritesheets/${sprite}.config.json`);
	let data = null;
	try {
		data = require(`./../assets/spritesheets/${sprite}.data.json`);
	} catch (error) {
		console.warn(`No data file found for ${sprite}. Continuing without.`);
	}
	if(!config || !src){
		throw new Error("Missing sprite data for sprite ", sprite);
	}
	return {
		config,
		src,
		data,
		// TODO: Invert this
		expectedFrameMs: (1 / config.fps * 1000),
		startFrame: 0,
		endFrame: config.frameCount,
		frame: 0,
		lastRenderedFrame: 0,
		resetFrame: true
	};
}

class Sprite extends React.Component {

	state = {
		src: null,
		startFrame: 0,
		endFrame: 1,
		frame: 0,
		lastRenderedFrame: 0,
		data: null,
		config: null,

		lastSprite: null,
		lastAnimation: null,

		resetFrame: false
	}

	constructor(props){
		super(props);
		this.actualFrame = 0;
	}

	static getDerivedStateFromProps(props, state){
		let ret = null;
		let data = state.data;
		if(props.sprite !== state.lastSprite){
			let config = getSpriteState(props);
			data = config.data;
			ret = Object.assign({}, ret, config, {
				lastSprite: props.sprite
			});
		}
		// Animation state changes will overwrite sprite state changes
		if(props.animation !== state.lastAnimation){
			ret = Object.assign({}, ret, getAnimationState(data, props.animation), {
				lastAnimation: props.animation
			});
		}
		return ret;
	}

	componentDidUpdate(){
		if(this.state.resetFrame){
			this.setState({
				resetFrame: false
			});
			this.actualFrame = this.state.startFrame;
		}
	}

	onAnimationFrame(time, lastTime){
		let { loop } = this.props;
		let { expectedFrameMs, data, startFrame, endFrame, frame, lastRenderedFrame } = this.state;
		let dt = (time - lastTime) / expectedFrameMs;
		frame = this.actualFrame;
		lastRenderedFrame = frame;
		frame = (frame + 1 * dt);

		if(frame > endFrame){
			if(loop){
				frame -= (endFrame - startFrame);	
			} else {
				frame = endFrame;
			}
		}
		
		if(Math.floor(frame) !== Math.floor(this.state.frame)){
			this.setState({
				frame,
				lastRenderedFrame
			});
		}
		this.actualFrame = frame;

		if(frame !== lastRenderedFrame){
			let { onFrame } = this.props;
			if(onFrame && data && data.frames){
				let callbackKeys = Object.keys(onFrame);
				callbackKeys.forEach(key => {
					if(data.frames.hasOwnProperty(key)){
						let framePosition = data.frames[key];
						if(framePosition >= lastRenderedFrame && framePosition < frame){
							onFrame[key]();
						}
					}
				});
			}
		}
	}

	render(){
		let { width, style, sprite } = this.props;
		let { src, frame, config } = this.state;
		if(!config || !src){
			return null;
		}

		let sheetWidth = width * config.framesWide;
		let sheetHeight = (width * config.frameAspect) * config.framesHigh;
		
		let f = Math.floor(frame);
		let frameX = (f % config.framesWide) * width;
		let frameY = Math.floor(f / config.framesWide) * width * config.frameAspect;
		return <div style={Object.assign({}, {
			width: `${width}px`,
			height: `${width * config.frameAspect}px`,
			overflow: "hidden",
			position: "relative"
		}, style)}>
			<img alt={`${sprite}-animation`} style={{
				position: "absolute",
				left: `-${frameX}px`,
				top: `-${frameY}px`,
				width: `${sheetWidth}px`,
				height: `${sheetHeight}px`,
				pointerEvents: "none"
			}} src={src}/>
		</div>
	}

}

Sprite.defaultProps = {
	width: 60,
	loop: true,
	animation: null
}

export default withAnimationFrame(Sprite, animationMs);