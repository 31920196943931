import React from "react";
import { Mouseable } from './';
import withAnimationFrame from "react-animation-frame";
import listenToDpi from "./../Utils/DPI";

class Paginator extends React.Component {

	state = {
		selectedIndex: -1,
		dpi: 1,
	}

	canvasRefs = [];
	arcPosition = 0;

	componentDidMount(){
		this.unsub = listenToDpi(dpi => this.setState({dpi}));
	}

	componentWillUnmount(){
		if(this.unsub){
			this.unsub();
		}
	}

	onAnimationFrame(t, lastT){
		let { speed, value, size, progressColor, autoProceed } = this.props;
		let { dpi } = this.state;
		if(!autoProceed){
			return;
		}
		// let dt = t - lastT;
		let rate = 1;
		if(this.state.selectedIndex !== -1){
			rate = 0.5;
		}

		this.arcPosition += rate * (Math.PI * 2) / (speed * 60);

		if(this.arcPosition >= Math.PI * 2){
			let { onChange, pages } = this.props;
			this.arcPosition = 0;
			this.setState({
				selectedIndex: -1
			});
			onChange((value + 1) % pages.length);
		}

		for(let i = 0; i<this.canvasRefs.length; i++){
			let canvas = this.canvasRefs[i];
			if(!canvas){
				return;
			}
			let ctx = canvas.getContext('2d');

			ctx.save();
			ctx.scale(dpi, dpi);
			ctx.clearRect(0, 0, size, size);
			ctx.beginPath();
			ctx.lineCap = "round";
			ctx.strokeStyle = progressColor;
			ctx.lineWidth = 2;
			ctx.arc(
				size * 0.5,
				size * 0.5,
				size * 0.5 - 1,
				- Math.PI * 0.5,
				- Math.PI * 0.5 + (value === i ? this.arcPosition : Math.PI * 2)
			);
			if(value === i){
				ctx.stroke();	
			} else {
				ctx.fill();
			}
			ctx.restore();
		}
	}

	handleSelected = i => {
		let { onChange, value } = this.props;
		let { selectedIndex } = this.state;
		
		if(value !== i){
			this.arcPosition = 0;
			if(onChange){
				onChange(i);
			}
		}
		this.setState({
			selectedIndex: selectedIndex === i ? -1 : i
		});
	}

	render(){
		let { pages, style, value, activeColor, inactiveColor, size } = this.props;
		let { dpi, selectedIndex } = this.state;
		return <div style={style}>
			{
				pages.map((p, i) => <Mouseable key={i} fancyStyle="pressable" style={{
					display: "inline-block",
					width: `${size}px`,
					height: `${size}px`,
					borderRadius: `${size * 0.5}px`,
					// backgroundColor: inactiveColor,
					marginRight: "12px",
					position: "relative",
				}} onClick={() => this.handleSelected(i)}>
					<canvas style={{
						position: "absolute",
						top: "0px", left: "0px",
						width: `${size}px`,
						height: `${size}px`,
					}} ref={ref => {
						while(this.canvasRefs.length < i){
							this.canvasRefs.push(undefined);
						}
						this.canvasRefs[i] = ref;
					}} width={size * dpi} height={size * dpi} />
					<div style={{
						position: "absolute",
						top: "0px", left: "0px",
						transition: "0.5s",
						backgroundColor: inactiveColor,
						height: `${size}px`,
						width: `${size}px`,
						borderRadius: `${size * 0.5}px`,
						transform: `scale(${value === i ? 0 : 1})`,
					}} />
					<div style={{
						position: "absolute",
						top: "0px", left: "0px",
						transition: "0.5s",
						backgroundColor: activeColor,
						height: `${size}px`,
						width: `${size}px`,
						borderRadius: `${size * 0.5}px`,
						transform: `scale(${value === i ? ( selectedIndex === i ? 0.6 : 0.8) : 0})`,
					}} />
				</Mouseable>)
			}
		</div>
	}
}

Paginator.defaultProps = {
	size: 14,
	speed: 10,
	progressColor: "#001FFF"
}

export default withAnimationFrame(Paginator);