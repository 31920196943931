
export const dark = "#2C2C2C";

export const light = "#F3F3F3";

export const highlight = "#001FFF";

export const cta = "#42FFDA";

export const error = "#E33800";

export const warning = "#E45B00";