import React, { useState, useEffect } from "react";
import { useLocalStorage } from "./../../hooks";
import { Input, TouchScroll, Button, LoadWheel, PaginatedContent } from "./../../UI";
import { PDFDocument, degrees } from 'pdf-lib'
import FliperatorPageSet from "./FliperatorPageSet";
import { handleUploadPDF, getPdfJs, downloadPDF } from "./../../Utils/PDFUtils";
import { error as errorColor } from "./../../Colors";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
	apiKey: "AIzaSyDxZua-TZLtjhD7aeKg7eqZ77OB47EWcjw",
	authDomain: "fliperature.firebaseapp.com",
	databaseURL: "https://fliperature.firebaseio.com",
	projectId: "fliperature",
	storageBucket: "fliperature.appspot.com",
	messagingSenderId: "833376243295",
	appId: "1:833376243295:web:c07eb572292eb87b7795ea",
	measurementId: "G-2PXKL71FGK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


const FliperatorInput = ({displayPdf, title, value, onChange, pageRange, spread, offsetPages, headerChildren}) => {
	return <div className="fliperator-input" style={{
		display: "flex",
		flexDirection: "column",
		margin: "12px 0px",
	}}>
		<div style={{
			display: "flex",
			flexDirection: "row",
			alignItems: "center"
		}}>
			<p style={{
				margin: "6px 0px",
				display: "inline-flex",
				marginRight: "12px",
				width: "120px",
			}}><b>{title}</b></p>
			<Input style={{
				display: "inline-flex",
				width: "60px",
			}} value={value} onChange={onChange} type="integer" />
			{ headerChildren }
		</div>
		<div style={{
			width: "100%",
			//height: "300px",
			position: "relative"
		}}>
			<FliperatorPageSet
				displayPdf={displayPdf}
				pageRange={pageRange}
				spread={spread}
				offsetPages={offsetPages}
			/>
		</div>
	</div>
}

const getErrorMessage = error => {
	switch(error){
		case "auth/wrong-password": return "Incorrect password";
		case "auth/user-not-found": return "This email address is not registered";
		default: return "Something went wrong ¯\\(ツ)/¯";
	}
}

const Fliperator = () => {

	const [ page, setPage ] = useState(0);
	const [ error, setError ] = useState(null);

	const [ authEmail, setAuthEmail ] = useState("");
	const [ authPassword, setAuthPassword ] = useState("");
	const [ signedIn, setSignedIn ] = useState(false);
	const [ signingIn, setSigningIn ] = useState(true);

	const [ loadingFile, setLoadingFile ] = useState(false);
	const [ displayPdf, setDisplayPdf ] = useState(null);
	const [ editPdf, setEditPdf ] = useState(null);

	const [ generating, setGenerating ] = useState(false);
	const [ generatedPdf, setGeneratedPdf ] = useState(null);
	const [ generatedPdfRaw, setGeneratedPdfRaw ] = useState(null);
	const [ downloading, setDownloading ] = useState(false);

	const [ inputKey, setInputKey ] = useState(0);
	const [ inputFileName, setInputFileName ] = useState("");

	const [ staticPagesFront, setStaticPagesFront ] = useLocalStorage("static-pages-front", 0, "integer");
	const [ staticPagesBack, setStaticPagesBack ] = useLocalStorage("static-pages-back", 0, "integer");
	const [ lastForwardPageNumber, setLastForwardPageNumber ] = useLocalStorage("last-forward-page", -1, "integer");
	const [ arrowPageNumber, setArrowPageNumber ] = useLocalStorage("arrow-page-index", -1, "integer");
	const [ flipPageNumber, setFlipPageNumber ] = useLocalStorage("flip-page-index", -1, "integer");
	const [ blankPageNumber, setBlankPageNumber ] = useLocalStorage("blank-page-index", -1, "integer");

	useEffect(() => {
		return firebase.auth().onAuthStateChanged(firebaseUser => {
			console.log("Signed in changes:", !!firebaseUser);
			setSignedIn(!!firebaseUser);
			setSigningIn(false);
		});
	}, []);

	const handleGenerate = async () => {

		setGenerating(true);

		const { data } = await firebase.functions().httpsCallable("flip")({
			config: {
				titlePageCount: staticPagesFront,
				pagesForwards: lastForwardPageNumber - 1,
				suffixPages: staticPagesBack,
				totalPageCount: editPdf.getPages().length - 3, // minus the three special pages,
			}
		});
		if(data.error){
			console.error(`Error getting flip data: `, data.error);
			setError(data.error);
			return;
		}

		const { pages } = data;

		const generated = await PDFDocument.create()

		for(let i = 0; i<pages.length; i++){
			let index = pages[i].pageIndex;
			if(index === -1){
				switch(pages[i].special){
					case "flip":
						index = flipPageNumber - 1;
						break;
					case "blank":
						index = blankPageNumber - 1;
						break;
					case "arrow":
						index = arrowPageNumber - 1;
						break;
					default: throw new Error("Unhandled special page: ", pages[i].special);
				}
			}

			const [p] = await generated.copyPages(editPdf, [index])
			
			if(pages[i].flipped){
				p.setRotation(degrees(180));
			}

			generated.addPage(p)

		}


		const pdfBytes = await generated.save()
		const pdfjs = getPdfJs();
		const loadingTask = await pdfjs.getDocument(pdfBytes)
		const pdfjsDoc = await loadingTask.promise;
		setGeneratedPdf(pdfjsDoc);
		setGeneratedPdfRaw(generated);

		setGenerating(false);
	}

	

	const renderHeader = (label, onBack) => <div style={{
		display: "flex",
		flexDirection: "row",
		maxWidth: "700px",
	}}>
		<h3 style={{
			flex: 1,
		}}>{label}</h3>
		{
			page > 0 ? <Button
				label="Back"
				theme="tiny"
				icon="triangle_left"
				iconSide="left"
				disabled={page === 0}
				onClick={() => {
					if(onBack){
						onBack();
					}
					setPage(page - 1);
				}}
			/> : null
		}
	</div>

	const handleSignIn = async () => {
		console.log("Signing in...");
		setSigningIn(true);
		setError(null);
		firebase.auth().signInWithEmailAndPassword(authEmail, authPassword).catch(error => {
			console.log("Error signing in :/", error);
			setError(error.code);
		}).finally(() => {
			console.log("Cleanup");
			setSigningIn(false);
		})
	}

	const ready = displayPdf && editPdf;
	const totalPageCount = displayPdf?.numPages;

	const autoLastForwardPage = Math.floor((totalPageCount - 3 - staticPagesBack - staticPagesFront) / 2 + staticPagesFront);

	return <TouchScroll className="page-overflow-container">
	{
		!signedIn ? <>
			{
				signingIn ? <div style={{
					width: "48px",
					height: "48px",
					position: "relative",
				}}>
					<LoadWheel />
				</div> : <>
					<h1>Sign In</h1>
					<Input value={authEmail} onChange={setAuthEmail} />
					<Input type="password" value={authPassword} onChange={setAuthPassword} onSubmit={handleSignIn} />
					{
						error && <p style={{
							fontStyle: "italic",
							color: errorColor
						}}>{getErrorMessage(error)}</p>
					}
					<Button label="Sign In" onClick={handleSignIn} />
				</>
			}
		</> : <div className="page-overflow-content">
			<div style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center"
			}}>
				<h1 style={{
					display: "inline-flex",
					flex: 1,
				}}>Fliperator</h1>
				<Button theme="hollow" label="Sign out" onClick={() => firebase.auth().signOut()} />
			</div>
			<PaginatedContent
				page={page}
			>
				<div>
					{ renderHeader("Turn books into Fliperature") }
					<div style={{
						position: 'relative',
						display: "inline-flex",
					}}>
						<Button label="Upload PDF" />
						<input key={inputKey} style={{
							position: "absolute",
							inset: "0px",
							opacity: 0,
							cursor: "pointer",
						}} type="file" onChange={async evt => {
							if(evt.target.files.length > 0){
								setLoadingFile(true);

								const uploadData = await handleUploadPDF(evt);
								if(uploadData){
									const { editable, displayable, fileName } = uploadData;
									setInputFileName(fileName);
									setEditPdf(editable);
									setDisplayPdf(displayable);
									setPage(1)
								}

								setLoadingFile(false);
							}
						}} />
					</div>
					{
						loadingFile ? <div style={{
							position: "relative",
							width: "24px",
							height: "24px",
							display: "inline-flex"
						}}>
							<LoadWheel />
						</div> : null
					}
				</div>
				<div>
					{ renderHeader("How the flip do you want it?", () => setInputKey(inputKey + 1)) }
					<p>Configure the details below, then hit Generate.</p>
					<Button label="Generate" onClick={() => {
						handleGenerate();
						setPage(2)
					}} />
					{
						ready ? <>
							<div>
								<div>
									<FliperatorInput
										displayPdf={displayPdf}
										title="Title pages"
										value={staticPagesFront}
										onChange={setStaticPagesFront}
										pageRange={{ fromPage: 0, toPage: staticPagesFront }}
										spread
										offsetPages={-1}
									/>
									<FliperatorInput
										displayPdf={displayPdf}
										title="Suffix pages"
										value={staticPagesBack}
										onChange={setStaticPagesBack}
										pageRange={{ fromPage: totalPageCount - staticPagesBack, toPage: totalPageCount}}
									/>
								</div>
								<div>
									<FliperatorInput
										displayPdf={displayPdf}
										title="Last forward page"
										value={lastForwardPageNumber}
										onChange={setLastForwardPageNumber}
										pageRange={{ single: lastForwardPageNumber - 1 }}
										headerChildren={<>
											<Button
												style={{
													marginLeft: "12px",
													display: "inline-flex",
												}}
												disabled={lastForwardPageNumber === autoLastForwardPage}
												label="Auto"
												onClick={() => setLastForwardPageNumber(autoLastForwardPage)}
											/>
										</>}
									/>
								</div>
								<div>
									<h5 style={{
										marginTop: "18px",
									}}>Special pages</h5>
									<p style={{
										margin: "12px 0px 6px 0px",
									}}>These pages will be removed from the book we do the layout – make sure they're right at the end of your PDF.</p>
									<FliperatorInput
										displayPdf={displayPdf}
										title="Arrow page"
										value={arrowPageNumber}
										onChange={setArrowPageNumber}
										pageRange={{ single: arrowPageNumber - 1 }}
									/>
									<FliperatorInput
										displayPdf={displayPdf}
										title="Flip page"
										value={flipPageNumber}
										onChange={setFlipPageNumber}
										pageRange={{ single: flipPageNumber - 1 }}
									/>
									<FliperatorInput
										displayPdf={displayPdf}
										title="Blank page"
										value={blankPageNumber}
										onChange={setBlankPageNumber}
										pageRange={{ single: blankPageNumber - 1 }}
									/>
								</div>
							</div>
						</> : null
					}
				</div>
				<div>
					{
						generating ? <div style={{
							margin: "12px",
							width: "30px",
							position: "relative",
						}}>
							<p><i>Fliperating, please hold...</i></p>
							<div style={{
								position: "relative",
								height: "48px",
							}}>
								<LoadWheel />
							</div>
						</div> : null
					}
					{
						!generating && generatedPdf ? <>
							{ renderHeader("Ta-da?") }
							<p>Does everything look right? If so, jam the Download button.</p>
							<Button
								label="Download"
								onClick={async () => {
									setDownloading(true);
									setPage(3);
									await downloadPDF(generatedPdfRaw, `Flipped ${inputFileName}`);
									setDownloading(false);
								}}
							/>
							<div style={{
								width: "100%",
								//height: "300px",
								position: "relative"
							}}>
								<FliperatorPageSet
									spread
									displayPdf={generatedPdf}
									height={500}
									offsetPages={-1}
								/>
							</div>
						</> : null
					}
				</div>
				<div>
					{
						downloading ? <>
							<p><i>Making a brand new file, just for you...</i></p>
							<div style={{
								margin: "12px",
								width: "30px",
								height: "48px",
								position: "relative",
							}}>
								<LoadWheel />
							</div>
						</> : null
					}
					{
						!downloading ? <>
							{ renderHeader("Nice.") }
							<p>Enjoy.</p>
						</> : null 
					}
					
				</div>
			</PaginatedContent>
		</div>
	}
	</TouchScroll>
}



export default Fliperator;