import React from "react";

class ResourcesBackground extends React.Component {

	render(){
		return <div style={{position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", backgroundColor: "#F3F3F3"}} />
	}

}

export default ResourcesBackground;