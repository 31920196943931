let dpiListenerIid = 0;
let dpiListeners = {};

// From https://stackoverflow.com/a/29653772
window.matchMedia('screen and (min-resolution: 2dppx)').addListener(function(e) {
	console.log("dpi changes!", window.devicePixelRatio);
	Object.values(dpiListeners).forEach(cb => cb(window.devicePixelRatio));
});

const listenToDpi = func => {
	let id = `${dpiListenerIid++}`;
	dpiListeners[id] = func;
	func(window.devicePixelRatio);
	return () => delete(dpiListeners[id]);
}

export const initialDpi = () => window.devicePixelRatio;

export default listenToDpi;