import React from "react";
import listen from "./ReduxListen";
import { ReactReduxContext } from "react-redux";


export default (dynamicMapStateToProps) => {

	let paths = Object.keys(dynamicMapStateToProps).map(k => ({
		propName: k,
		path: Array.isArray(dynamicMapStateToProps[k]) ? dynamicMapStateToProps[k] : dynamicMapStateToProps[k].split("/")
	}));


	return WrappedComponent => {

		const displayName = WrappedComponent.displayName || WrappedComponent.name || "UnnamedComponent";



		class Wrapper extends React.Component {

			static contextType = ReactReduxContext;

			componentDidMount(){
				this.wildcards = {};
				this.hasWildcards = false;
				for(let i = 0; i<paths.length; i++){
					let p = paths[i].path;
					for(let j = 0; j<p.length; j++){
						if(p[j][0] === "*"){
							this.hasWildcards = true;
							let wc = p[j].substring(1, p[j].length);
							this.wildcards[wc] = this.props[wc];
						}
					}
				}
				console.log("DynamicConnect mounts", displayName);
				this.mounted = true;
				this.reconnect();
			}

			componentDidUpdate(){
				this.checkPropChanges();
			}

			checkPropChanges = () => {
				if(this.hasWildcards){
					let extra = this.extraProps();
					let wcKeys = Object.keys(this.wildcards);
					let shouldReconnect = false;
					for(let i = 0; i<wcKeys.length; i++){
						let k = wcKeys[i];
						let wcValue = this.props[k] || extra[k];
						if(this.wildcards[k] !== wcValue){
							this.wildcards[k] = wcValue;
							shouldReconnect = true;
						}
					}
					if(shouldReconnect){
						this.reconnect();
					}
				}
			}

			extraProps = () => {
				let { store } = this.context;
				let state = store.getState();
				let extra = {};
				for(let i = 0; i<paths.length; i++){
					let p = paths[i];
					let obj = state;
					for(let j = 0; j<p.path.length; j++){
						let path = p.path[j];
						if(path[0] === "*"){
							let wc = path.substring(1, path.length);
							if(!this.wildcards){
								extra[wc] = undefined;
								break;
							} else {
								path = this.wildcards[wc] || extra[wc] || this.props[wc];
							}
						}
						if(j === p.path.length - 1){
							extra[p.propName] = obj[path];
						} else {
							if(obj && obj[path]){
								obj = obj[path];
							} else {
								extra[p.propName] = undefined;
								break;
							}
						}
					}
				}
				return extra;
			}

			rerender = () => {
				this.setState({});
			}

			reconnect = (props=this.props) => {

				let extra = this.extraProps();
				let listenObject = {};
				for(let i = 0; i<paths.length; i++){
					let p = paths[i];
					let o = listenObject;
					for(let i = 0; i<p.path.length; i++){
						let pathKey = p.path[i];
						if(pathKey[0] === "*"){
							let wc = pathKey.substring(1, pathKey.length);
							if(!props.hasOwnProperty(wc) && !extra.hasOwnProperty(wc)){
								console.warn(`DynamicConnect ${displayName} wildcard ${wc} is missing`);
							}
							pathKey = props[wc] || extra[wc];
							if(pathKey === null || pathKey === undefined){
								break;
							}
						}
						if(i === p.path.length - 1){
							o[pathKey] = () => {
								if(!this.mounted){
									if(this.__unsubDynamicMapStateToProps){
										this.__unsubDynamicMapStateToProps();
									}
									return;
								}
								this.checkPropChanges();
								this.rerender();
							};
						} else {
							if(!o[pathKey]){
								o[pathKey] = {};
							}
							o = o[pathKey];	
						}
					}
				}

				
				if(this.__unsubDynamicMapStateToProps){
					this.__unsubDynamicMapStateToProps();
				}
				let { store } = this.context;
				if(this.mounted){
					this.__unsubDynamicMapStateToProps = listen(store, listenObject, {dispatchInitial: true});	
				}
			}

			componentWillUnmount(){
				this.mounted = false;
				if(this.__unsubDynamicMapStateToProps){
					this.__unsubDynamicMapStateToProps();
				}
			}

			render(){
				let extra = this.extraProps();
				return <WrappedComponent {...this.props} {...extra} />
			}

		}

		Wrapper.displayName = `DynamicConnect(${displayName})`;
		// Wrapper.contextTypes = {
		// 	store: ReactReduxContext
		// }

		return Wrapper;

	}

}