import React from "react";
import Mouseable from "./Mouseable";
import Icon from "./Icon";
import SpinningIcon from "./Icon/SpinningIcon";

export const themes = {
	_default: {
		iconColor: "#000",
		labelColor: "#000",
		labelFontFamily: "Montserrat",
		backgroundColor: "#42FFDA",
		border: "2px outset white",
		borderRadius: "1px",
		iconSize: "28px",
		opacity: 1,
		labelWeight: 500,
		labelSize: "14px",
		iconPadding: "56px",
		padding: "14px",
		paddingTop: "10px",
		paddingBottom: "10px",
		display: "inline-block",
	},
	secondary: {
		backgroundColor: "#EBEBEB",
	},
	hollow: {
		backgroundColor: "transparent",
		fontWeight: "400",
		border: "none",
		iconPadding: "48px",
		padding: "6px",
		paddingTop: "10px",
		paddingBottom: "10px",
	},
	tiny: {
		iconSize: "12px",
		backgroundColor: "transparent",
		border: "none",
	},
	stubby: {
		backgroundColor: "#EBEBEB",
		padding: "4px",
		iconSize: "18px",
		labelSize: "12px",
		paddingTop: "4px",
		paddingBottom: "4px",
	}
}

const themesDisabled = {
	_default: {
		labelColor: "#A0A0AA",
		iconColor: "#A0A0AA",
	},
}

const themesHovered = {
	_default: {
		backgroundColor: "#A3FFED",
	},
	secondary: {
		backgroundColor: "#f2f2f2",
	},
	hollow: {
		backgroundColor: "transparent",
		opacity: 0.65,
		border: "none",
	},
	tiny: {
		backgroundColor: "transparent",
		opacity: 0.5,
	},
	stubby: {
		backgroundColor: "#f2f2f2",
	},
}

const themesHeld = {
	_default: {
		border: "2px inset white",
	},
	tiny: {
		border: "none",
	},
	hollow: {
		backgroundColor: "transparent",
		opacity: 0.5,
		border: "none",
	},
}

const passedProps = [ "onClick", "hoveredStyle", "disabled" ];

class Button extends React.Component {

	getThemeStyles = (themeSheets=[themes]) => {
		let { theme } = this.props;
		if(!Array.isArray(theme)){
			theme = [theme];
		}
		let ret = {};
		for(let i = 0; i<themeSheets.length; i++){
			ret = Object.assign({}, ret, themeSheets[i]._default);
			for(let j = 0; j<theme.length; j++){
				ret = Object.assign({}, ret, themeSheets[i][theme[j]]);
			}
		}
		return ret;
	}

	renderIcon = () => {
		let { label, icon, iconSide, alt, customStyles, disabled, loading, loadingIcon } = this.props;
		customStyles = customStyles || {};
		let { iconColor, iconSize, padding, paddingHorz } = this.getThemeStyles(disabled ? [themes, themesDisabled] : [themes]);
		iconColor = this.props.iconColor || iconColor;
		icon = loading ? loadingIcon : icon;
		let Comp = loading ? SpinningIcon : Icon;
		return <Comp
			icon={icon}
			color={iconColor}
			alt={alt}
			style={Object.assign({
				width: iconSize,
				height: iconSize,
				position: "absolute",
				top: "50%",
				transform: label ? "translateY(-50%)" : "translate(-50%, -50%)",
				left: !label ? "50%" : (iconSide === "left" ? (paddingHorz || padding) : null),
				right: label && iconSide === "right" ? (paddingHorz || padding) : null,
			}, customStyles.icon)}
		/>
	}

	render(){
		let { label, icon, style, iconSide, disabled, customStyles } = this.props;
		customStyles = customStyles || {};
		let {
			labelColor, labelSize, labelWeight,
			backgroundColor, borderRadius,
			textDecoration,
			border,
			iconPadding, padding, paddingHorz, paddingVert, paddingTop, paddingBottom,
			display, margin,
			labelFontFamily
		} = this.getThemeStyles(disabled ? [ themes, themesDisabled ] : [ themes ]);
		let passProps = {};
		passedProps.forEach(p => this.props[p] ? passProps[p] = this.props[p] : null);
		return <Mouseable
			style={Object.assign({
				borderRadius,
				border,
				backgroundColor,
				textDecoration,
				margin,
				width: "fit-content",
				height: "fit-content",
				cursor: disabled ? null : "pointer",
				display,
				paddingTop: paddingTop || paddingVert || padding,
				paddingBottom: paddingBottom || paddingVert || padding,
				paddingLeft: icon && label && iconSide === "left" ? iconPadding : (paddingHorz || padding),
				paddingRight: icon && label && iconSide === "right" ? iconPadding : (paddingHorz || padding),
				position: "relative",
			}, style, customStyles.container)}
			hoveredStyle={this.getThemeStyles([themesHovered])}
			heldStyle={this.getThemeStyles([themesHeld])}
			{...passProps}
		>
			{
				icon && iconSide === "left" ? this.renderIcon() : null
			}
			{
				label ? <p style={Object.assign({
					color: labelColor,
					fontFamily: labelFontFamily,
					fontWeight: labelWeight,
					margin: "0px",
					fontSize: labelSize,
				}, customStyles.label)}>{label}</p> : null
			}
			{
				icon && iconSide === "right" ? this.renderIcon() : null
			}
		</Mouseable>
	}

}

Button.defaultProps = {
	iconSide: "right",
	iconAlt: "",
	loadingIcon: "loading"
}

export default Button;