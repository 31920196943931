import React from "react";
import Icon from "./Icon";
import SpinningIcon from "./SpinningIcon";
import listenToDpi, { initialDpi } from "./../Utils/DPI";
import { dark, error as errorColor } from "./../Colors";

class Image extends React.Component {

	state = {
		testLoading: false,
		loading: false,
		error: null,
		widthIndex: -1,
		dpi: initialDpi(),
		width: 1,
		height: 1,
		snap: "none",
	}

	loaded = [];

	componentDidMount(){
		this.measure();
		this.unsubDpi = listenToDpi(dpi => dpi !== this.state.dpi ? this.setState({dpi}, this.measure) : null);
		window.addEventListener("resize", this.measure);
	}

	componentWillUnmount(){
		if(this.unsubDpi){
			this.unsubDpi();
		}
		window.removeEventListener("resize", this.measure);
		if(this.clearLoadTimeout){
			clearTimeout(this.clearLoadTimeout);
			this.clearLoadTimeout = null;
		}
	}

	measure = () => {
		let { fit, aspect, widths } = this.props;
		widths = widths.sort();
		// if(fit){
		// 	aspect = 1 / aspect;
		// }
		let { dpi } = this.state;
		let { width, height } = this.containerRef.getBoundingClientRect();
		width *= dpi;
		height *= dpi;
		let snap = "none";

		// if(fit){
		let heightAtFullWidth = width / aspect;
		if(heightAtFullWidth < height){
			snap = fit ? "width" : "height";
			height = heightAtFullWidth;
		} else {
			snap = fit ? "height" : "width";
			width = height * aspect;
		}

		let widthIndex = -1;
		for(let i = widths.length-1; i>=0; i--){
			let imageWidth = widths[i];
			let imageHeight = widths[i] / aspect;

			if(widthIndex === -1 || (imageWidth >= width && imageHeight >= height)){
				widthIndex = i;
			}
		}

		if(widthIndex !== this.state.widthIndex || snap !== this.state.snap){
			this.setState({
				widthIndex,
				snap
			});
			if(!this.loaded.includes(widthIndex)){
				this.handleLoadBegin(widthIndex);
			}
		}

	}

	handleLoadBegin = (widthIndex) => {
		console.log("handleLoadBegin", this.props.debugName, widthIndex);
		if(this.clearLoadTimeout){
			clearTimeout(this.clearLoadTimeout);
		}
		this.clearLoadTimeout = setTimeout(() => {
			this.clearLoadTimeout = null;
			this.setState({
				loading: true
			});
		}, 400);
	}

	handleLoadEnd = (widthIndex) => () => {
		console.log("handleLoadEnd", this.props.debugName, widthIndex);
		if(this.clearLoadTimeout){
			clearTimeout(this.clearLoadTimeout);
			this.clearLoadTimeout = null;
		}
		if(!this.loaded.includes(widthIndex)){
			this.loaded.push(widthIndex);
		}
		if(widthIndex === this.state.widthIndex && this.state.loading){
			this.setState({
				loading: false,
			});
		}
	}

	handleLoadError = evt => {
		this.setState({
			error: true
		});
	}

	renderComponent = () => {
		let { type, alt, widths, extension, src, elementRef, elementClassName } = this.props;
		widths = widths.sort();
		let { widthIndex, loading, snap } = this.state;
		let width = snap === "width" ? `100%` : "auto";
		let height = snap === "height" ? `100%` : "auto";
		let elementStyle = {
			opacity: loading ? 0.1 : 1,
			position: "absolute",
			width,
			height,
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
		}
		switch(type){
			case "video":
			return <video
					className={elementClassName}
					ref={elementRef ? ref => elementRef(ref) : null}
			        playsInline
			        autoPlay
			        muted
			        src={require(`../${src}@${widths[widthIndex]}w.${extension}`)}
			        onLoadedData={this.handleLoadEnd(widthIndex)}
			        style={elementStyle}
		    	/>
			case "image":
			default:
			return <img
					className={elementClassName}
					ref={elementRef ? ref => elementRef(ref) : null}
					alt={alt}
					src={require(`../${src}@${widths[widthIndex]}w.${extension}`)}
					style={elementStyle}
					onLoad={this.handleLoadEnd(widthIndex)}
					onError={this.handleLoadError}
				/>
		}
	}

	render(){
		let { expand, style, className } = this.props;
		let { widthIndex, loading, error } = this.state;
		return <div className={className} ref={ref => this.containerRef = ref} style={Object.assign(
			expand ? {
			position: "absolute",
			top: "0px",
			left: "0px",
			width: "100%",
			height: "100%",
			overflow: "hidden",
		} : {}, style)}>
			{
				widthIndex !== -1 ? this.renderComponent() : <p>-1!</p>
			}
			{
				loading ? <SpinningIcon
					style={{
						width: "30px",
						height: "30px",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
					icon="loading"
					color={dark}
				/> : null
			}
			{
				error ? <Icon
					style={{
						width: "30px",
						height: "30px",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
					icon="error"
					color={errorColor}
				/> : null
			}
		</div>
	}

}

Image.defaultProps = {
	extension: "jpg",
	alt: "",
	type: "image",
}

export default Image;