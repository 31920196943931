import React from "react";
import classname from "classnames";
import "./ShopBackground.css";

class ShopBackground extends React.Component {

	state = {
		dark: false,
	}

	componentDidMount(){
		if(!window.flip){
			window.flip = {};
		}
		window.flip.dark = () => this.setState({dark: !this.state.dark});
	}

	render(){
		let { dark } = this.state;
		return <div className={classname("shop-background", {dark})} style={{
			width: "100%",
			height: "100%",
			// backgroundImage: "linear-gradient(#FFE9F9, #B9E1FF)",
		}} />
	}

}

export default ShopBackground;