import React from "react";
import isTouchDevice from "is-touch-device";
import classnames from "classnames";
import "./TouchScroll.css";

class TouchScroll extends React.Component {

	state = {
		isTouch: false
	}

	componentDidMount(){
		if(isTouchDevice()){
			this.setState({
				isTouch: true
			});
		}
	}

	render(){
		let { x, y } = this.props;
		let { isTouch } = this.state;
		let c = "touch-scroll";
		if(x && !y){
			c = "touch-scroll-x";
		}
		if(y && !x){
			c = "touch-scroll-y";
		}
		return <div {...this.props} className={classnames(this.props.className, c, {"is-touch": isTouch})}/>
	}
}

export default TouchScroll;