import React from "react";
import { cta } from "./../../Colors";
import { Motion, spring } from "react-motion";

class KeteCount extends React.Component {

	state = {
		open: false,
		count: 0,
		textWidth: 0,
		pulseIndex: 0,
	}

	static getDerivedStateFromProps(props, state){
		return {
			open: props.count > 0,
			count: props.count > 0 ? props.count : state.count,
			pulseIndex: props.count !== state.count && props.count > 0 && state.open ? state.pulseIndex + 1 : state.pulseIndex
		};
	}

	componentDidMount(){
		this.measure();
	}

	componentDidUpdate(){
		this.measure();
	}

	measure = () => {
		if(this.pRef){
			let { width } = this.pRef.getBoundingClientRect();
			if(width !== this.state.textWidth){
				this.setState({textWidth: width});
			}
		}
	}

	render(){
		let { open, textWidth, count, pulseIndex } = this.state;
		return <Motion defaultStyle={{
				in: 0,
				pulse: 0,
			}} style={{
				in: spring(open ? 1 : 0, {stiffness: 150, damping: 13}),
				pulse: spring(pulseIndex, {stiffness: 150, damping: 13}),
			}}>
		{
			s => <div style={{
				position: "absolute",
				right: "0px",
				bottom: "0px",
			}}>
				<div style={{
					position: "absolute",
					left: "0px",
					top: "0px",
					backgroundColor: cta,
					border: "2px solid white",
					width: `${Math.max(textWidth + 18, 24)}px`,
					height: "24px",
					borderRadius: "12px",
					transform: `translate(-50%, -50%) scale(${s.in + (s.pulse - pulseIndex) * 0.3})`,
					opacity: s.in
				}}>
					<p className="sans" ref={ref => this.pRef = ref} style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						fontWeight: "600",
						color: "black",
						margin: "0px",
					}}>{count}</p>
				</div>
			</div>
		}
		</Motion>
	}

}

export default KeteCount;