import React from "react";
import PropTypes from 'prop-types';
import noScroll from 'no-scroll';
import classnames from "classnames";
import "./Modal.css";

let modalsOpen = [];
let iid = 0;

class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.id = `${iid++}`;
    }

    componentDidMount(){
        document.addEventListener("keydown", this.handleKey, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKey, false);
        if(modalsOpen.includes(this.id)){
            this.removeFromModals();
        }
    }

    handleKey = evt => {
        let { onClose, open, allowEscapeClose } = this.props;
        if(
            evt.key === "Escape" && onClose && open && allowEscapeClose &&
            modalsOpen.length > 0 && modalsOpen[modalsOpen.length - 1] === this.id
        ) {
            onClose();
        }
    }

    componentDidUpdate(){
        let { open } = this.props;
        let included = modalsOpen.includes(this.id);
        if(open && !included){
            if(!modalsOpen.includes(this.id)){
                modalsOpen.push(this.id);
                noScroll.on();   
            }
        }
        if(!open && included){
            this.removeFromModals();
        }
    }

    removeFromModals = () => {
        let index = modalsOpen.indexOf(this.id);
        if(index !== -1){
            modalsOpen = [...modalsOpen.slice(0, index), ...modalsOpen.slice(index + 1, modalsOpen.length)];
            if(modalsOpen.length === 0){
                noScroll.off();
            }
        }
    }

    render() {
        let { open, onClose, allowBackgroundClose, children, style, centered, enterFrom } = this.props;
        return <div className={classnames("modal-bounds", {open, centered, [`enter-from-${enterFrom}`]: true})}>
            <div className="modal-background" onClick={allowBackgroundClose ? onClose : null} />
            <div className="modal" style={style}>
                { children }
            </div>
        </div>
    }

}

Modal.defaultProps = {
    minWidth: "450px",
    maxWidth: "95%",
    allowBackgroundClose: false,
    allowEscapeClose: true,
    enterFrom: "bottom",
    centered: true,
}

Modal.propTypes = {
    centered: PropTypes.bool,
    enterFrom: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    width: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    allowBackgroundClose: PropTypes.bool,
    allowEscapeClose: PropTypes.bool,
}


export default Modal;