import React from "react";
import { Button } from "./../../UI";
import { addToKete } from "./../../Backend/Shopify";
import { getKete } from "./../../Singletons"

class AddToKeteButton extends React.Component {

	state = {
		loading: false,
	}

	handleAdd = async () => {
		let { product, variant, quantity } = this.props;
		this.setState({
			loading: true
		});

		await addToKete(product, variant, quantity);
		// TODO: Handle errors here

		this.setState({
			loading: false
		});

		getKete().open({
			added: ({product, variant, quantity})
		});
	}

	render(){
		let { loading } = this.state;
		return <Button
			style={{
				width: "170px",
			}}
			icon="add-to-kete"
			label={loading ? "Adding..." : "Add to Kete"}
			onClick={this.handleAdd}
			disabled={loading}
			loading={loading}
		/>
	}

}

export default AddToKeteButton;