import React from "react";
import Icon from "./Icon";
import Mouseable from "./Mouseable";
import asPropsResponder from "./../Utils/PropsResponder";

class Input extends React.Component {

	state = {
		focus: false,
		hoveredSuggestionIndex: -1,
		highlightedSuggestionIndex: -1,
		suggestionResults: [],
		preferredWidth: 1,
		preferredHeight: 1,
	};

	onPropsChange(propName, props){
		switch(propName){
			case "focus":
				let { focus } = props;
				if(focus && this.inputRef){
					this.inputRef.focus();
					this.setState({focus: true});
				}
			break;
			case "suggestions":
				// let { suggestions } = this.props;
				// this.suggestionSearcher = suggestions ? new FuzzySearch(
				// 	suggestions.map(s => ({s})),
				// 	["s"],
				// 	{sort: true}
				// ) : null;
				this.rebuildSuggestions();
			break;
			case "value":
				this.rebuildSuggestions(props);
			break;
			default:
			break;
		}
	}

	rebuildSuggestions = (props=this.props) => {
		if(this.suggestionSearcher){
			let suggestionResults = this.suggestionSearcher.search(props.value);
			this.setState({ suggestionResults, highlightedSuggestionIndex: -1 });
		}
	}

	componentDidUpdate(){
		let { preferredWidth, preferredHeight } = this.state;
		let { width, height } = this.sizeRef.getBoundingClientRect();
		if(width !== preferredWidth || height !== preferredHeight){
			this.setState({
				preferredWidth: width,
				preferredHeight: height,
			});
		}
	}

	focus = () => {
		this.inputRef.focus();
	}

	blur = () => {
		this.inputRef.blur();
	}

	render(){
		let {
			onChange, onSubmit, onFocus, onBlur,
			placeholder, disableSubmit, type, disabled,
			suggestionsName, onRemoveSuggestion, value, minHeight, maxHeight, wrap,
			style, customStyles, keepFocusOnSubmit,
			children
		} = this.props;
		let {
			suggestionResults,
			focus,
			highlightedSuggestionIndex,
			hoveredSuggestionIndex,
			preferredHeight
		} = this.state;

		customStyles = customStyles || {};

		suggestionResults = suggestionResults.slice(0, Math.min(suggestionResults.length, 5))
		suggestionResults = suggestionResults.filter(sr => sr.s !== value);
		let InputComponent = wrap ? "textarea" : "input";
		return <div style={Object.assign({
			position: "relative",
		}, style)}>
			{
				focus && !disabled && (children || suggestionResults.length > 0) ? <div
					className="appendages-container"
					style={Object.assign({
						position: "absolute",
						top: "100%",
						width: "100%",
						zIndex: -1,
						backgroundColor: "#efefef",
						boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
						marginTop: `${-preferredHeight}px`, paddingTop: `${preferredHeight}px`,
						overflow: "hidden",
					}, customStyles.childContainer)}
					onMouseLeave={() => this.setState({hoveredSuggestionIndex: -1})}
				>
					{
						suggestionResults.length > 0 ? 
						<div className="suggestions-container" style={
							Object.assign({
								paddingTop: "6px",
							}, customStyles.suggestionsContainer)}>
							<p style={Object.assign({
								margin: "2px 6px",
								fontWeight: "700",
								fontSize: "10px",
							}, customStyles.suggestionsName)}>{suggestionsName}</p>
							{
								suggestionResults.map((sr, i) => <Mouseable
									key={i}
									style={Object.assign({
										backgroundColor: i === highlightedSuggestionIndex ? "#76A5FF" : null,
										cursor: "pointer",
										padding: "6px",
										position: "relative",
									}, customStyles.suggestionItemContainer, i === highlightedSuggestionIndex ? customStyles.suggestionItemContainerSelected : null)}
									hoveredStyle={{
										backgroundColor: "#76A5FF"
									}}
									onMouseEnter={() => {
										this.setState({
											highlightedSuggestionIndex: -1,
											hoveredSuggestionIndex: i,
										});
									}}
									onMouseDown={evt => {
										evt.preventDefault();
										if(onChange){
											onChange(sr.s);	
										}
									}}
								>
									<p style={Object.assign({
										margin: "0px",
										marginRight: onRemoveSuggestion ? "24px" : null
									}, customStyles.suggestionItemText, i === highlightedSuggestionIndex ? customStyles.suggestionItemTextSelected : null)}>"{sr.s}"</p>
									{
										onRemoveSuggestion && hoveredSuggestionIndex === i ? <Mouseable
											style={{
												position: "absolute",
												top: "50%",
												right: "6px",
												transform: "translateY(-50%)",
											}}
											onMouseDown={evt => {
												evt.preventDefault();
												evt.stopPropagation();
												onRemoveSuggestion(sr.s, i);
											}}
										>
											<Icon
												style={{
													width: "12px",
													height: "12px",
												}}
												alt={`Remove from ${suggestionsName}`}
												icon="cross"
											/>
										</Mouseable> : null
									}
									
								</Mouseable>)
							}
						</div> : null
					}
					{
						children ? <div
							onMouseDown={() => {
								this.shouldRefocus = true;
							}}
						>
							{ children }
						</div> : null
					}
				</div> : null
			}
			<div style={Object.assign({
				position: "relative",
				width: "100%",
				zIndex: 2,
				borderRadius: "6px",
				overflow: "hidden",
				border: "1px solid #707070",
			}, customStyles.container)}>
				<p ref={ref => this.sizeRef = ref} style={Object.assign({
					margin: "0px",
					position: "absolute",
					top: "0px", left: "0px", right: "0px",
					opacity: 0,
					pointerEvents: "none",
					fontSize: "14px",
					padding: "6px",
					paddingLeft: "12px",
				}, customStyles.input)}>{value || "_"}</p>
				<InputComponent
					ref={ref => this.inputRef = ref}
					type={type}
					style={Object.assign({
						width: "100%",
						minHeight: wrap ? minHeight : null,
						maxHeight: wrap ? maxHeight : null,
						height: wrap ? `${preferredHeight}px` : null,
						fontSize: "14px",
						padding: "6px",
						paddingLeft: "12px",
						backgroundColor: disabled ? "#eee" : "#fff",
						border: "none",
						outline: "none",
					}, customStyles.input)}
					onFocus={() => {
						this.setState({focus: true});
						if(onFocus){
							onFocus();
						}
						this.rebuildSuggestions();
					}}
					onBlur={() => {
						if(this.shouldRefocus){
							setTimeout(() => this.inputRef.focus(), 20);
							this.shouldRefocus = false;
						} else {
							this.setState({
								focus: false,
								highlightedSuggestionIndex: -1
							});
							if(onBlur){
								onBlur();
							}
						}
					}}
					disabled={disabled}
					value={value}
					onChange={evt => onChange(evt.target.value)}
					onKeyDown={evt => {
						if(!disabled && !disableSubmit && onSubmit && evt.key === "Enter"){
							if(highlightedSuggestionIndex === -1){
								onSubmit();
								if(!keepFocusOnSubmit){
									this.inputRef.blur();	
								}
							} else {
								if(onChange){
									onChange(suggestionResults[highlightedSuggestionIndex].s);
								}
								this.setState({
									highlightedSuggestionIndex: -1,
								});
							}
						}
						if(!disabled && evt.key === "ArrowDown" && suggestionResults.length - 1 > highlightedSuggestionIndex){
							evt.preventDefault();
							this.setState({
								highlightedSuggestionIndex: highlightedSuggestionIndex + 1
							});
						}
						if(!disabled && evt.key === "ArrowUp" && highlightedSuggestionIndex > -1){
							evt.preventDefault();
							this.setState({
								highlightedSuggestionIndex: highlightedSuggestionIndex - 1
							});
						}
						if(evt.key === "Escape"){
							if(suggestionResults.length > 0){
								this.setState({suggestionResults: []});
							} else {
								this.inputRef.blur();
							}
						}
					}}
				/>
				{
					placeholder && !value ? <p style={Object.assign({
						position: "absolute",
						pointerEvents: "none",
						color: "#666",
						marginTop: "0px",
						top: "6px",
						left: "12px",
						fontSize: "14px",
						fontWeight: "400",
						fontStyle: "italic",
					}, customStyles.placeholder)}>{placeholder}</p> : null
				}
			</div>
		</div>
	}

}

Input.defaultProps = {
	value: "",
	wrap: false,
	keepFocusOnSubmit: false,
}

export default asPropsResponder(Input, ["focus", "suggestions", "value"], { onMount: true });