import GenericReducer from "./Utils/ReduxGeneric";
import quick from "./Utils/ReduxQuick";

const g = new GenericReducer("location", {
	page: "home",
	args: {}
}, quick);

export const setPage = g.child("page").allow("set");
export const setArgs = g.child("args").allow("set");
export const setPageAndArgs = g.multiple([
	g.child("page").allow("set", v => v),
	g.child("args").allow("set", v => v),
], (page, args) => [ {value: page}, {value: args} ]);

export default g.apply();