import React, { Fragment } from "react";
import { SpinningIcon, Icon, Modal, Button, Mouseable, LoadWheel } from "./../../UI";
import classnames from "classnames";
import { connect } from "react-redux";
import { getKeteTotal, getCheckoutUrl, pollCheckoutCompletion } from "./../../Backend/Shopify";
import KeteCount from "./KeteCount";
import KeteLineItem from "./KeteLineItem";
import "./Kete.css";
import { CSSTransition } from "react-transition-group";

let instance;
export const getKete = () => instance;

const mapStateToProps = state => ({
	kete: state.kete,
	checkoutCompleteCount: state.ui.checkoutCompleteCount,
});

class Kete extends React.Component {

	state = {
		open: false,
		fakeLoaded: true,
		fakeCount: 0,
		openInfo: null,
		redirecting: false,
		redirectCount: 0,
		checkoutCompleteCount: 0,
		showComplete: false,
		showBackupButton: false,
	}

	constructor(props){
		super(props);
		this.keteButtonContainerRef = React.createRef();
	}

	static getDerivedStateFromProps(props, state){
		if(props.checkoutCompleteCount !== state.checkoutCompleteCount){
			return {
				checkoutCompleteCount: props.checkoutCompleteCount,
				showComplete: true,
			}
		}
		return {};
	}

	componentDidMount(){
		instance = this;
		if(!window.flip){
			window.flip = {};
		}
		window.flip.toggle = () => this.setState({fakeLoaded: !this.state.fakeLoaded});
		window.flip.incrementFakeCount = n => this.setState({fakeCount: this.state.fakeCount + n});
	}

	open = (openInfo={}) => {
		this.setState({
			open: true,
			openInfo,
		});
	}

	handleCheckout = () => {
		this.setState({
			redirecting: true,
			redirectCount: 3,
			showBackupButton: false,
		});
		this.redirectTimeout = setTimeout(this.incrementRedirectCount, 1000);
	}

	incrementRedirectCount = () => {
		let { redirectCount } = this.state;
		
		if(redirectCount <= 1){
			this.handleRedirect();
		} else {
			this.setState({
				redirectCount: redirectCount - 1
			});
			this.redirectTimeout = setTimeout(this.incrementRedirectCount, 1000);
		}
	}

	handleRedirect = () => {
		let checkoutWindow = window.open(getCheckoutUrl());
		if(!checkoutWindow || checkoutWindow.closed || typeof(checkoutWindow.closed) === "undefined"){
			this.setState({
				showBackupButton: true
			});
		} else {
			this.startCompletionPolling();
			this.setState({
				redirecting: false,
				open: false,
			});
		}
	}

	startCompletionPolling = () => {
		console.log("startCompletionPolling");
		this.cancelPoll = pollCheckoutCompletion();
	}

	handleRedirectCancel = () => {
		this.setState({
			redirecting: false
		});
		if(this.redirectTimeout){
			clearTimeout(this.redirectTimeout);
		}
	}

	render(){
		let { open, redirecting, redirectCount, showComplete,
			showBackupButton
			// fakeLoaded, fakeCount
		} = this.state;
		let { kete, hidden } = this.props;
		let { loaded, lineItems } = kete;
		// let lineItems = editableKete ? editableKete.lineItems : [];
		let itemCount = 0;
		lineItems.forEach(li => itemCount += li.quantity);
		// itemCount = fakeCount;
		return <Fragment>
			<Modal
				open={open}
				onClose={() => this.setState({open: false})}
				allowBackgroundClose
				style={{
					// top: "10px",
					// right: "10px",
					width: "600px",
					maxWidth: "90%",
					padding: "36px",
				}}
			>
				<h2>Kete</h2>
				{
					itemCount === 0 ? <div style={{
						textAlign: "center",
						padding: "0px 36px"
					}}>
						<p style={{fontSize: "14px"}}><strong>Your kete is empty</strong></p>
						<p>When you find something you like, click the <strong>Add to Kete</strong> button and it'll show up here.</p>
					</div> : <Fragment>
						{
							lineItems.map((li, i) => <KeteLineItem
								open={open}
								key={i}
								item={li}
							/>)
						}
					</Fragment>
				}
				<div style={{
					height: "1px",
					backgroundColor: "#979797",
					margin: "12px 0px",
				}} />
				{
					lineItems.length > 0 ? <div style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}>
						<p style={{
							display: "inline-flex",
							margin: "6px 0px"
						}}>All prices are in NZD.</p>
						<div style={{flex: 1}} />
						<p className="sans" style={{
							margin: "6px 0px",
							display: 'inline-flex',
							fontSize: "18px",
							fontWeight: "600",
							textAlign: "right",
							color: "black"
						}}>${getKeteTotal().toFixed(2)}</p>
					</div> : null
				}
				<div style={{
					display: "flex",
					flexDirection: "row",
					marginTop: "24px",
				}}>
					<Button
						label="Close"
						icon="close"
						theme="secondary"
						iconSide="left"
						onClick={() => this.setState({open: false})}
					/>
					<div style={{flex: 1}} />
					<Button
						disabled={lineItems.length === 0}
						label="Checkout"
						icon="checkout"
						iconSide="right"
						onClick={this.handleCheckout}
					/>
				</div>
			</Modal>
			<CSSTransition
				timeout={300}
				nodeRef={this.keteButtonContainerRef}
				classNames="kete-button-container"
				in={!hidden}
			>
				<div className={classnames("kete-button-container-base", {"hidden-initial": hidden})} style={{
					position: "absolute",
					top: "0px",
					right: "0px"
				}} ref={this.keteButtonContainerRef}>
					<Mouseable
						style={{
							position: "fixed",
							top: "20px",
							right: "20px",
							padding: "6px,"
						}}
						fancyStyle="pressable"
						heldClassName={"held"}
						onClick={() => open ? this.setState({open: false}) : this.open()}
						disabled={!loaded}
					>
						<Icon
							style={{
								width: "40px",
								height: "40px",
								opacity: loaded ? 1 : 0.3
							}}
							icon="kete"
							color="#000"
							alt="Open kete"
						/>
						<KeteCount count={itemCount} />
						{
							loaded ? null : <LoadWheel />
						}
					</Mouseable>
				</div>
			</CSSTransition>
			<Modal open={redirecting} style={{
				textAlign: "center",
			}}>
				<div style={{
					position: "relative",
					height: "60px",
					width: "100%"
				}}>
					<SpinningIcon style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "48px",
						height: "48px",
					}} icon="loading" />
				</div>
				<h4>Hold tight...</h4>
				<p>We're directing you to our payment portal. See you soon.</p>
				{
					showBackupButton ? <Fragment>
						<hr />
						<p>Hmmm... Page didn't load automatically?</p>
						<a onClick={this.startCompletionPolling} href={getCheckoutUrl()} target="_blank" rel="noopener noreferrer"><p style={{
							fontSize: "14px",
							fontWeight: "600",
						}}>Click here to visit checkout.</p></a>
					</Fragment> : <p style={{fontSize: "14px", margin: "12px 0px"}}>{redirectCount}</p>
				}
				<div style={{
					marginTop: "24px",
					display: "flex",
					flexDirection: "row",
				}}>
					<Button
						label="Cancel"
						theme="secondary"
						onClick={this.handleRedirectCancel}
					/>
					<div style={{flex: 1}} />
					{
						false ? <Button
							label="Go"
							theme="cta"
							onClick={this.handleRedirect}
						/> : null
					}
				</div>
			</Modal>
			<Modal
				open={showComplete}
				allowBackgroundClose
				onClose={() => this.setState({showComplete: false})}
				style={{
					textAlign: "center",
					padding: "36px 64px",
				}}
			>
				<h3>Order Complete</h3>
				<p>Seriously, thank you so much.</p>
				<Button
					style={{
						marginTop: "24px",
					}}
					label="I am a Legend"
					icon="heart"
					theme="cta"
					onClick={() => this.setState({showComplete: false})}
				/>
			</Modal>
		</Fragment>
	}

}

export default connect(mapStateToProps)(Kete);