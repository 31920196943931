import React from "react";
import FliperatorPage from "./FliperatorPage";
import { Button, Mouseable } from "./../../UI";
import { useState } from "react";
import "./FliperatorPageSet.css";
import { cta } from "./../../Colors";

const FliperatorPageSet = ({displayPdf, spread, pageRange, style, height=300, offsetPages=0}) => {

	if(!pageRange){
		pageRange = { fromPage: 0, toPage: displayPdf.numPages };
	}

	const { single, fromPage, toPage } = pageRange;

	const isSingle = single !== undefined && single !== null;

	let pages = [];
	if(isSingle){
		pages = [single];
	} else {
		pages = Array.apply(null, Array(toPage - fromPage)).map((_, i) => fromPage + i);
	}

	const previewPages = [];
	for(let i = offsetPages; i<pages.length; i += spread ? 2 : 1){
		previewPages.push(i);
	}

	const [ index, setIndex ] = useState(offsetPages);

	return <div
		style={Object.assign({
			display: "table",
			flexDirection: "row",
			marginBottom: "23px",
		}, style)}
	>
		<div style={{
			display: "table-cell",
			flexDirection: "column",
			paddingRight: "12px",
		}}>
			{
				isSingle ? null : <div style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginRight: "12px",
				}}>
					<Button
						label="Previous"
						style={{
							margin: "6px 6px 6px 0px",
						}}
						icon="triangle_left"
						disabled={index <= 0}
						iconSide="left"
						theme="stubby"
						onClick={() => setIndex(index - (spread ? 2 : 1))}
					/>
					<p style={{
						display: "inline-block",
						flex: 1,
						textAlign: "center",
						margin: "6px 24px",
					}}><b>{index + 1}{spread && index + 2 < pages.length ? `, ${index + 2}` : ""}</b> of {toPage - fromPage}</p>
					<Button
						label="Next"
						icon="triangle_right"
						iconSide="right"
						disabled={index >= toPage - fromPage - (spread ? 2 : 1)}
						theme="stubby"
						onClick={() => spread ? setIndex(index + 2) : setIndex(index + 1)}
					/>
				</div>
			}
			<div style={{
				display: 'flex',
				flexDirection: "row",
				justifyContent: "center"
			}}>
				<FliperatorPage
					staticHeight={height}
					displayPdf={displayPdf}
					pageIndex={pages[Math.max(index, 0)]}
					style={{
						opacity: index < 0 ? 0 : 1,
					}}
				/>
				{
					spread ? <FliperatorPage
						staticHeight={height}
						displayPdf={displayPdf}
						pageIndex={pages[Math.min(index + 1, pages.length - 1)]}
						style={{
							opacity: index < pages.length - 1 ? 1 : 0,
						}}
					/> : null
				}
			</div>
		</div>
		{
			isSingle ? null : <div className="preview-area" style={{
				display: "table-cell",
				width: `${(spread ? 240 : 120) + 20}px`, // A little extra for scrollbar
				position: "relative",
			}}>
				<div style={{
					position: "absolute",
					top: "38px", left: "0px", bottom: "0px", right: "0px",
					overflow: "auto",
				}}>
					{
						previewPages.map((i) => {

							const active = i === index;

							return <Mouseable
								key={i}
								style={{
									marginBottom: "6px",
									border: `2px solid ${active ? cta : "transparent"}`,
									width: "fit-content",
								}}
								onClick={() => setIndex(i)}
							>
								<FliperatorPage
									staticWidth={120}
									displayPdf={displayPdf}
									pageIndex={pages[Math.max(i, 0)]}
									inline={spread}
									style={{
										opacity: i < 0 ? 0 : 1
									}}
								/>
								{
									spread ? i < pages.length - 1 ? <FliperatorPage
										staticWidth={120}
										displayPdf={displayPdf}
										pageIndex={pages[i + 1]}
										inline
									/> : <div style={{
										width: "120px",
										display: "inline-block",
									}} /> : null
								}
							</Mouseable>
						})
					}
				</div>
			</div>
		}
	</div>

}

export default FliperatorPageSet;