import GenericReducer from "./Utils/ReduxGeneric";
import quick from "./Utils/ReduxQuick";

const g = new GenericReducer("ui", {
	checkoutCompleteCount: 0,
	media: "computer",
	siteDark: false,
	splashIndex: 0,
	backgroundDark: false,
}, quick);

export const incrementCheckoutComplete = g.child("checkoutCompleteCount").allow("increment");

export const setMedia = g.child("media").allow("set");

export const setSiteDark = g.child("siteDark").allow("set");
export const toggleSiteDark = g.child("siteDark").allow("toggle");

export const setSplashIndex = g.child("splashIndex").allow("set");

export const setBackgroundDark = g.child("backgroundDark").allow("set");

export default g.apply();