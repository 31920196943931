import React, { Fragment } from "react";
import { Mouseable, Icon, Button, Image, Input, LoadWheel } from "./../../UI";
import { getConfirmer } from "./../../Singletons";
import {
	getProduct, getVariant, getProductPrice, getVariantAvailable,
	removeLineItem, editLineItemQuantity
} from "./../../Backend/Shopify";
import { warning } from "./../../Colors";
import { connect } from "react-redux";

const mapStateToProps = state => ({
	media: state.ui.media
});

class KeteLineItem extends React.Component {

	state = {
		open: false,
		quantity: -1,
		lastVerifiedQuantity: -1,
		checkoutQuantity: -1,
		removing: false,
		loading: false,
		error: null,
		editing: false,
		inputEmpty: false,
	}

	static getDerivedStateFromProps(props, state){
		let { item } = props;
		return {
			open: props.open,
			checkoutQuantity: item.quantity,
			quantity: props.open && !state.open ? item.quantity : state.quantity
		};
	}

	componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout);
		}
	}

	handleQuantity = async quantity => {
		if(quantity === 0){
			let confirmed = await this.checkRemove();
			if(!confirmed){
				return;
			}
			this.removeItem();
		} else {
			this.setState({
				quantity
			});
		}
	}

	checkRemove = async () => {
		let { item } = this.props;
		let { product, variant } = item;
		let { title } = getProduct(product, variant);
		return await getConfirmer().ask({
			title: `Remove “${title}” from your kete?`
		});
	}

	removeItem = async () => {
		let { item } = this.props;
		let { id } = item;
		this.setState({
			quantity: 0,
			removing: true,
		});
		// TODO: Handle errors
		await removeLineItem(id);
	}

	componentDidUpdate(){
		let { open, removing, quantity, checkoutQuantity, lastVerifiedQuantity, editing } = this.state;
		if(open && !removing && !editing && quantity !== checkoutQuantity && quantity !== lastVerifiedQuantity){
			if(this.timeout){
				clearTimeout(this.timeout);
			}
			this.timeout = setTimeout(this.updateQuantity, 800);
		}
	}

	updateQuantity = async () => {
		if(this.timeout){
			clearTimeout(this.timeout);
		}
		let { item } = this.props;
		let { quantity } = this.state;
		this.setState({
			loading: true,
			error: null,
			lastVerifiedQuantity: quantity
		});

		let { error } = await editLineItemQuantity(item.id, quantity);
		// TODO: Handle errors

		this.setState({
			loading: false,
			error
		});
	}

	handleInputComplete = async () => {
		let { quantity, checkoutQuantity } = this.state;
		if(quantity === 0){
			let confirmed = await this.checkRemove();
			if(confirmed){
				this.removeItem();
			} else {
				this.setState({
					quantity: checkoutQuantity,
					editing: false,
					inputEmpty: false,
				});
			}
		} else {
			this.setState({editing: false, inputEmpty: false})	
		}
	}

	renderPrice = price => {
		return <p className="sans" style={{
			textAlign: "right",
			margin: "3px 6px 3px 0px",
		}}>${price.toFixed(2)}</p>
	}

	renderQuantityControls = () => {
		let { item, media } = this.props;
		let { product, variant } = item;
		let { loading, editing, lastVerifiedQuantity, quantity, checkoutQuantity, inputEmpty } = this.state;
		let moreAvailable = getVariantAvailable(product, variant);
		return <div style={{width: "150px", alignSelf: media.is("phone") ? "flex-end" : "flex-start"}}>
			<div style={{
				verticalAlign: "middle",
				position: "relative",
				textAlign: "right",
			}}>
				<div style={{
					top: "50%",
					left: "100%",
					width: "24px",
					height: "24px",
					position: "absolute",
					transform: "translateY(-50%)",
					zIndex: 3,
				}}>
					{
						loading ? <LoadWheel entranceDelay={0} size={14} color="#C3C3C3" style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "transate(-50%, -50%)",
						}} /> : null
					}
					{
							
						!loading && !editing &&
							lastVerifiedQuantity === quantity &&
							checkoutQuantity !== quantity ? <Icon
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							width: "24px",
							height: "24px",
							transform: "translate(-50%, -50%)",
						}}
						icon="warning"
						color={warning}
						/> : null
					}
				</div>
				<Input
					style={{
						display: "inline-block",
						width: "80px",
						verticalAlign: "middle",
					}}
					customStyles={
						{
							input: {
								border: "none",
								outline: "none",
								textAlign: "right",
								fontSize: "18px",
								fontFamily: "Libre Baskerville",
							},
							container: {
								border: "none",
							}
						}
					}
					value={inputEmpty ? "" : quantity}
					type="integer"
					onChange={val => {
						this.setState({
							quantity: val.length > 0 ? parseInt(val) : 0,
							inputEmpty: val.length === 0,
							editing: true,
						});
					}}
					onSubmit={this.handleInputComplete}
					onBlur={this.handleInputComplete}
				/>
				<div style={{display: "inline-block", verticalAlign: "middle",}}>
					<Button
						style={{display: "block"}}
						disabled={!moreAvailable}
						theme="tiny"
						onClick={() => this.handleQuantity(quantity + 1)}
						icon="arrow_up"
						alt="More"
					/>
					<Button
						style={{display: "block"}}
						theme="tiny"
						disabled={quantity === 0}
						onClick={() => this.handleQuantity(quantity - 1)}
						icon="arrow_down"
						alt="Fewer"
					/>
				</div>
			</div>
		</div>
	}

	render(){
		let { item, media } = this.props;
		let { removing, loading } = this.state;
		let { product, variant } = item;
		let { title, subtitle, id } = getProduct(product, variant);
		let { titleVerbose } = getVariant(product, variant);
		let price = getProductPrice(product, variant);
		return <Fragment>
			<div
				style={{
					display: "flex",
					flexDirection: media.is("phone") ? "column" : "row",
					opacity: removing ? 0.5 : 1,
					position: "relative",
					padding: "18px 0px",
					pointerEvents: removing ? "none" : null,
				}}
			>
				<div style={{display: "flex", flexDirection: "row", width: "100%"}}>
					<div style={{
							display: "inline-flex",
							width: "80px",
							height: "80px",
							position: "relative",
							marginRight: "12px",
					}}>
						<Image
							expand
							src={`assets/images/${id}/shop-thumbnail`}
							widths={[128, 256, 512]}
						/>
					</div>
					<div style={{
						display: "inline-flex",
						flex: 1,
						flexDirection: "column",
					}}>
						<p style={{fontSize: "14px", fontWeight: "600", margin: "6px 0px"}}><strong>{title}</strong></p>
						<p style={{margin: "6px 0px"}}>{subtitle}</p>
						<div>
							<p style={{
								margin: "6px 0px",
								display: "inline-block",
								verticalAlign: "middle"
							}} className="sans">{titleVerbose}</p>
							<Icon
								style={{
									display: "inline-block",
									verticalAlign: "middle",
									width: "24px",
									height: "24px",
									marginLeft: "6px"
								}}
								icon={variant === "verso" ? "verso-small" : "rector-small"}
							/>
						</div>
					</div>
				</div>
				{
					this.renderQuantityControls()
				}
				{
					removing ? <LoadWheel style={{
						position: 'absolute',
						top: "50%", left: "50%", transform: "translate(-50%, -50%)",
					}} /> : null
				}
			</div>
			<div style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			}}>
				<Mouseable
					style={{
						display: "inline-flex",
						textDecoration: "underline",
					}}
					disabled={loading || removing}
					hoveredStyle={{opacity: 0.6}}
					onClick={this.removeItem}
				>
					<p className="sans" style={{margin: "0px", color: "#565656"}}>Remove</p>
				</Mouseable>
				<div style={{display: "inline-flex", flex: 1}} />
				<div style={{
					display: "inline-flex",
				}}>
					{
						this.renderPrice(price)
					}
				</div>
			</div>
		</Fragment>
	}

}

export default connect(mapStateToProps)(KeteLineItem);