class Callbacks {
	constructor() {
		this._addListener = this._addListener.bind(this)
		this._removeListener = this._removeListener.bind(this)
		this.callbacks = {}
		this.iid = 0
	}

	// Convenience handler for addListener
	// Supports string (eventName, callback)
	// Supports object ({ eventName1: callback1, eventName2: callback2 })
	on(event, callback) {
		if (event === null || event === undefined) {
			console.warn(
				`Callbacks.on cannot be called with null or undefined event argument.`
			)
			return () => {}
		}
		switch (typeof event) {
			case 'string':
			case 'number':
			case 'boolean':
				return this._addListener(event, callback)
			case 'object':
				let keys = Object.keys(event)
				let all = keys.map(k => this._addListener(k, event[k]))
				return () => all.forEach(a => a())
			default:
				throw new Error(
					`Unhandled Callbacks.on signature arg1 type ${typeof event}`
				)
		}
	}

	onAny(events, callback, delay = 1) {
		let dispatchUnderway = null
		let args = [];
		function cb(_){
			args.push(Array.from(arguments));
			if (!dispatchUnderway) {
				dispatchUnderway = setTimeout(() => {
					callback(args)
					clearTimeout(dispatchUnderway)
					dispatchUnderway = null
					args = [];
				}, delay)
			}
		}
		let unsubs = events.map(e => this.on(e, cb))

		return () => {
			clearTimeout(dispatchUnderway)
			unsubs.forEach(us => us())
		}
	}

	_addListener(eventName, callback) {
		let id = this.iid++
		if (!this.callbacks[eventName]) {
			this.callbacks[eventName] = {}
		}
		this.callbacks[eventName][id] = callback
		return () => this._removeListener(eventName, id)
	}

	_removeListener(eventName, id) {
		if (this.callbacks[eventName]) {
			delete this.callbacks[eventName][id]
		} else {
			console.log(
				`Cannot remove listener from ${eventName} - no callbacks are defined.`
			)
		}
	}

	dispatch(eventName, _args) {
		let args = Array.from(arguments)
		args = args.slice(1, args.length)
		if (this.callbacks[eventName]) {
			for (let key in this.callbacks[eventName]) {
				this.callbacks[eventName][key](...args)
			}
		}
	}
}

export default Callbacks
