import React from "react";
import { Image } from "./../../UI";
import { mapMedia } from "./../../Utils/Responsive";
import { connect } from "react-redux";
import classnames from "classnames";
import "./Home.css";

const mapStateToProps = state => ({
	media: state.ui.media,
	splashIndex: state.ui.splashIndex,
});

class HomeBackground extends React.Component {

	state = {
		width: 0,
		height: 0,
	}

	componentDidMount(){
		window.addEventListener("resize", this.measure);
		this.measure();
	}

	componentWillUnmount(){
		window.removeEventListener("resize", this.measure);
	}

	measure = () => {
		let { width, height } = this.containerRef.getBoundingClientRect();
		if(width !== this.state.width || height !== this.state.height){
			this.setState({width, height});
		}
	}

	renderSplashBackground = index => {
		let { media, splashIndex } = this.props;
		let { width, height } = this.state;

		let inner;

		switch(index){
			case 0:
				inner = <Image
					debugName="Home Background"
					src="assets/images/white-spread"
					aspect={ 5185 / 3456 }
					widths={[2800, 1920, 1280, 1000, 720, 360]}
					expand
				/>
			break;
			default:
			case 1:
			{
				let imageAspect = 3582 / 3288;//1.4416875332;
				let leftParkAspect = 0;//0.2490105957;
				let h = height;
				let w = h * imageAspect;
				let right = `${Math.max(width - w * (1 - leftParkAspect), 0)}px`;
				inner = <img
					src={mapMedia(media, {
						tablet: require("./../../assets/images/frankenstein-splash@1280w.png"),
						large: require("./../../assets/images/frankenstein-splash@1800w.png"),
					})}
					style={{
						position: "absolute",
						top: "0px",
						right,
						height: "100%",
					}}
					alt=""
				/>
			}
			break;
		}

		return <div style={{
			width: "100%",
			height: "100%",
		}} className={classnames("background-splash-container", {open: index === splashIndex})}>
			{ inner }
		</div>
	}

	render(){
		return <div ref={ref => this.containerRef = ref} style={{
			width: "100%",
			height: "100%",
			backgroundColor: "white",
			position: "fixed",
			overflow: "hidden"
		}}>
			{ this.renderSplashBackground(0) }
			{ this.renderSplashBackground(1) }
		</div>
	}

}

export default connect(mapStateToProps)(HomeBackground);