import React, { useRef, useLayoutEffect, useState, useEffect, useCallback } from "react";
import useDPI from "./../../hooks/useDPI";

const FliperatorPage = ({style, displayPdf, pageIndex, staticWidth, staticHeight, inline}) => {

	const containerRef = useRef();
	const canvasRef = useRef();
	const [dimensions, setDimensions] = useState({width: 1, height: 1});
	const [canvasDimensions, setCanvasDimensions] = useState({width: 1, height: 1});
	const [missing, setMissing] = useState(false);
	const renderQueue = useRef([]);
	const dpi = useDPI();
	const [ page, setPage ] = useState(null)

	useEffect(() => {
		if(displayPdf && displayPdf.numPages > pageIndex){
			setMissing(false);
			displayPdf.getPage(pageIndex + 1).then(setPage);
		} else {
			setMissing(true);	
		}
	}, [pageIndex, displayPdf, setMissing]);

	const runRenderQueue = useCallback(async () => {
		if(renderQueue.current.length > 0){
			await renderQueue.current[0].promiseGetter();
			renderQueue.current.shift();
			runRenderQueue();
		}
	}, [renderQueue]);

	const addToRenderQueue = useCallback((promiseGetter, details) => {
		const shouldRun = renderQueue.current.length === 0;
		renderQueue.current.push({promiseGetter, details});
		if(shouldRun){
			runRenderQueue();
		}
	}, [renderQueue, runRenderQueue]);

	const rescale = useCallback(page => {
		if(!page){
			return;
		}
		const viewport = page.getViewport({ scale: 1, });
		let width, height;
		if(staticWidth){
			width = staticWidth;
			height = (viewport.viewBox[3] / viewport.viewBox[2]) * staticWidth;
		} else if (staticHeight){
			width = (viewport.viewBox[2] / viewport.viewBox[3]) * staticHeight;
			height = staticHeight;
		} else {
			const scaleToWidth = dimensions.width / viewport.viewBox[2];
			const scaleToHeight = dimensions.height / viewport.viewBox[3];
			if(scaleToWidth < scaleToHeight){
				width = viewport.width * scaleToWidth;
				height = viewport.height * scaleToWidth;
			} else {
				width = viewport.width * scaleToHeight;
				height = viewport.height * scaleToHeight;
			}
		}
		if(width !== canvasDimensions.width || height !== canvasDimensions.height){
			setCanvasDimensions({width, height});
		}
	}, [ dimensions, canvasDimensions, setCanvasDimensions, staticWidth, staticHeight ])

	const rerender = useCallback(page => {
		if(!page){
			return;
		}
		// if(renderLoadingCancel.current){
		// 	console.log("Clearing previous render...");
		// 	renderLoadingCancel.current();
		// 	renderLoadingCancel.current = null;
		// }
		// const scale = 1.5;
		// const viewport = page.getViewport({scale});

		// Prepare canvas using PDF page dimensions
		const canvas = canvasRef.current;

		const context = canvas.getContext('2d');

		const viewport = page.getViewport({ scale: 1 / dpi, });
		
		const { width/*, height*/ } = canvasDimensions;
		const desiredWidth = width;

		const scale = desiredWidth / viewport.width;
		const scaledViewport = page.getViewport({ scale });

		// Render PDF page into canvas context
		const renderContext = {
			canvasContext: context,
			viewport: scaledViewport
		};
		
		addToRenderQueue(() => {
			const renderTask = page.render(renderContext);
			return renderTask.promise;
		}, canvasDimensions);

	}, [ canvasRef, dpi, canvasDimensions, addToRenderQueue ]);


	useEffect(() => {
		rescale(page);
	}, [page, rescale])

	useEffect(() => {
		rerender(page);
	}, [page, rerender])

	useLayoutEffect(() => {
		if(containerRef.current){
			const { width, height } = containerRef.current.getBoundingClientRect();
			if(width !== dimensions.width || height !== dimensions.height){
				setDimensions({width, height});
			}
		}
	}, [setDimensions, dimensions])

	const { width, height } = canvasDimensions;

	return <div ref={containerRef} style={Object.assign({
		position: "relative",
		width: staticWidth ? `${staticWidth}px` : staticHeight ? `${width}px` : null,
		height: staticHeight ? `${staticHeight}px` : staticWidth ? `${height}px` : null,
		boxShadow: missing ? null : "2px 2px 10px rgba(0, 35, 121, 0.2)",
		display: inline ? "inline-block" : null,
	}, style)}>
		<canvas style={{
			position: "absolute",
			top: "0px",
			left: "0px",
			width: `${width}px`,
			height: `${height}px`
		}} ref={canvasRef} width={width * dpi} height={height * dpi} />
		{
			missing ? <div style={{
				position: 'absolute',
				top: "0px", left: "0px", right: "0px", bottom: "0px",
				textAlign: "center",
				paddingTop: "24px",
			}}>
				<p><i>No page.</i></p>
			</div> : null
		}
	</div>
}

export default FliperatorPage;