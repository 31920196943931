import React from "react";
import { TouchScroll, Sprite, Button } from "./../../UI";
import { connect } from "react-redux";

const mapStateToProps = state => ({
	media: state.ui.media,
});

class About extends React.Component {

	state = {
		animation: "page",
	}

	pagesTurned = 0;
	pagesBeforeFlip = 3;

	renderSprite = (width=60) => {
		let { animation } = this.state;
		return <Sprite width={width} animation={animation} sprite="holding" onFrame={{
			pageRest: () => {
				this.pagesTurned++;
				if(this.pagesTurned >= this.pagesBeforeFlip){
					this.pagesBeforeFlip = 2 + Math.ceil(Math.random() * 2);
					this.pagesTurned = 0;
					this.setState({
						animation: "flip"
					});
				}
			},
			flipRest: () => {
				this.setState({
					animation: "page"
				});
			}
		}} />
	}

	render(){
		let { media } = this.props;
		return <TouchScroll className="page-overflow-container">
			<div className="page-overflow-content" style={{maxWidth: "550px"}}>
				<h1>What on Earth is {media.is("phone") ? null : <br/>}going on?</h1>
				<div style={{
					position: 'relative',
					width: "100%",
					height: "100px"
				}}>
					<div style={{
						position: 'absolute',
						top: "50%", left: '50%', transform: "translate(-50%, -50%)",
					}}>
						{this.renderSprite(100)}
					</div>
				</div>
				<div style={{
					display: "flex",
					flexDirection: "row",
				}}>
					<div style={{
						display: "inline-flex",
						flex: 1,
						flexDirection: "column",
					}}>
						<h5>Fliperature is a book format designed for reading while lying down.</h5>
					</div>
					{
						// media.is("phone") ? null : <div style={{
						// 	display: "inline-flex",
						// 	padding: "0px 0px 0px 12px",
						// }}>
						// 	{
						// 		this.renderSprite()
						// 	}
						// </div>
					}
				</div>
				<p>Read on one side of the book. When you reach the end, flip it and continue reading.</p>
				<div style={{marginTop: "48px"}} />
				<p><strong>Proudly produced in Wellington, Aotearoa.</strong></p>
				<p>We work with local artists, print locally, and use sustainably sourced paper.</p>
				<div style={{marginTop: "24px"}} />
				<Button
					label="Enquiries"
					icon="email"
					onClick={() => window.location.href = "mailto:admin@fliperature.co.nz?subject=Fliperature"}
				/>
			</div>
		</TouchScroll>
	}

}

export default connect(mapStateToProps)(About);