import React from "react";
import Icon from "./Icon";
import "./SpinningIcon.css";

class SpinningIcon extends React.Component {

	render(){
		let { style, disabled } = this.props;
		let passed = Object.assign({}, this.props);
		delete(passed.style);
		return <div style={Object.assign({ position: "relative" }, style)}>
			<Icon className={disabled ? null : "spinning-icon-rotating"} style={{
				width: "100%",
				height: "100%",
				position: "absolute",
			}} {...passed} />
		</div>
	}

}

export default SpinningIcon;