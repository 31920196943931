import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { pages, setPage } from "./../Pages";
// import { light, dark } from "./../Colors";
import "./Nav.css";

const mapStateToProps = state => ({
	page: state.location.page,
	media: state.ui.media
});

class Nav extends React.Component {

	renderItem = (p, key) => {
		let { page, media } = this.props;
		if(p.hidden && page !== p.page){
			return null;
		}
		return <p
			key={key}
			onClick={() => setPage(p.page)}
			className={classnames("nav", {"nav-selected": page === p.page, "secret": p.hidden && p.page === page})}
			style={{
				// color: page === p.page ? null : (theme === "light" ? dark : light),
				display: media.is("phone") ? "inline-block" : "block",
			}}
		>{p.name}</p>
	}

	render(){
		let { style } = this.props;
		return <div style={style}>
			{
				pages.map(this.renderItem)
			}
		</div>
	}

}

export default connect(mapStateToProps)(Nav);