import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./PaginatedContent.css";
import classnames from "classnames";

const PaginatedContentPage = ({index, page, children}) => {

	const nodeRef = useRef();
	return <CSSTransition
		in={index === page}
		nodeRef={nodeRef}
		classNames="paginated-content-page"
		//unmountOnExit // TODO: This would be primo, but the sliding transitions are tricky
		timeout={300}
	>
		<div ref={nodeRef} className={classnames("paginated-content-page-base", {in: index === page})} style={{
			width: "100%",
			height: "100%",
			position: index === page ? null : "absolute",
			top: "0px",
			left: "0px",
		}}>
			<div className={classnames("paginated-content-position", { left: index < page, right: index > page })} style={{
				width: "100%",
				height: "100%",
			}}>
				{ children }
			</div>
		</div>
	</CSSTransition>
}

const PaginatedContent = ({style, children, page}) => {


	return <div style={Object.assign({
			position: "relative",
			whiteSpace: "nowrap",
			width: "100%",
			overflow: "hidden",
		}, style)}>
		{
			React.Children.map(children, (c, i) => <PaginatedContentPage key={i} children={c} index={i} page={page}/>)
		}
	</div>

}

export default PaginatedContent