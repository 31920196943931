
const recurse = (obj, oldState, newState, context={}, options={}) => {
	if(obj && typeof(obj) === "object"){
		let keys = Object.keys(obj);
		keys.forEach(key => {
			if(key[0] === "*"){
				let wildcardLabel = key.substring(1, key.length);
				let allStateKeys = {};
				if(newState){
					Object.keys(newState).forEach(k => allStateKeys[k] = k);	
				}
				if(oldState){
					Object.keys(oldState).forEach(k => allStateKeys[k] = k);	
				}
				let allKeys = Object.keys(allStateKeys);
				if(allKeys.length === 0 && options.force){
					recurse(obj[key], null, null, Object.assign({}, context, {
						__isEmpty: true
					}), options);
				}
				allKeys.forEach(k => {
					recurse(obj[key], oldState ? oldState[k] : null, newState ? newState[k] : null, Object.assign({}, context, {
						[wildcardLabel]: k
					}), options);
				});
			} else {
				recurse(obj[key], oldState ? oldState[key] : null, newState ? newState[key] : null, context, options);
			}
		});
	} else if(typeof(obj) === "function"){
		if(oldState !== newState || options.force){
			obj(newState, oldState, context);
		}
	} else {
		console.error("Unexpectedly dropped out of ReduxListen");
	}
}


// Call with this pathStringArray:
// 	[
//		"some/path/to/state",
//		"optionally/use/*wildcards/if/you/want"
//	],
//	(args) => { /* Handler fired if any of these paths change */ }
// 	args is an array of { newState, oldState, context } objects.
export const listenMultiple = (store, pathStringArray, callback, options={}) => {
	let lastState = store.getState();
	let shouldCallback = false;
	let args = [];
	const cb = (newState, oldState, context) => {
		shouldCallback = true;
		if(!context.__isEmpty){
			args.push({ newState, oldState, context });
		}
	}
	let listenPaths = {};
	let obj = listenPaths;
	for(let i = 0; i<pathStringArray.length; i++){
		let paths = pathStringArray[i].split("/");
		obj = listenPaths;
		for(let j = 0; j<paths.length; j++){
			if(j === paths.length - 1){
				obj[paths[j]] = cb;
			} else {
				if(!obj[paths[j]]){
					obj[paths[j]] = {};	
				}
				obj = obj[paths[j]];
			}
		}
	}

	const runRecurse = force => {
		shouldCallback = false;
		args = [];
		let newState = store.getState();
		let oldState = Object.assign({}, lastState);
		lastState = Object.assign({}, newState);
		recurse(listenPaths, oldState, newState, {}, { force });
		if(shouldCallback){
			callback(args);
		} else {
			if(force){
				console.error("Forced recursion unexpectedly not", pathStringArray.join("__"));
			}
		}
	}

	if(options.dispatchInitial){
		runRecurse(true);
	}

	return store.subscribe(runRecurse);
}

// Call with this paths structure:
//		{
//			path: {
//				to: {
//					state: (newState, oldState, context) => { /* handler */ }
//				}
//			},
//			// Wildcards supported
//			sections: {
//				"*sectionId": {
//					property: (newState, oldState, context) => { /* handler 2 */ } 
//				}
//			}
//				
//				
//		}

const listen = (store, paths, options={}) => {
	let lastState = store.getState();
	if(options.dispatchInitial){
		recurse(paths, lastState, lastState, {}, { force: true });
	}
	// TODO: This is a bit heavy for widespread use. Could refactor into a single store subscriber.
	return store.subscribe(() => {
		let newState = store.getState();
		let oldState = Object.assign({}, lastState);
		lastState = Object.assign({}, newState);
		recurse(paths, oldState, newState);
	});
}

export default listen;